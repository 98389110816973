import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/system";

const CustomButton = styled(Button)(({ theme, variant }) => ({
  minWidth: "150px",
  textTransform: "capitalize",
  borderRadius: "4px",
  padding: "4px 10px",
  fontSize: "12px",

  [theme.breakpoints.down("sm")]: {
    minWidth: "100px",
    fontSize: "0.875rem",
  },
  [theme.breakpoints.up("md")]: {
    minWidth: "150px",
  },

  ...(variant !== "text" && {
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",

    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.2)",
    },

    "&:active": {
      transform: "translateY(-2px)",
      boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.15)",
    },
  }),
}));

const CustomButtonComponent = ({ children, ...props }) => {
  return <CustomButton {...props}>{children}</CustomButton>;
};

export default CustomButtonComponent;
