import React from "react";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { fontSize, styled } from "@mui/system";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Custom Styled Component for the Select
const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiInputBase-input": {
    backgroundColor: "#F4F5F9",
    borderRadius: "8px",

    "&:focus": {
      borderColor: "#DBDCDE",
    },
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#F4F5F9",
    borderRadius: "8px",
    "& fieldset": {
      borderColor: "#DBDCDE", // Default border color
    },
    "&:hover fieldset": {
      borderColor: "#DBDCDE", // Hover border color
    },
    "&.Mui-focused fieldset": {
      borderColor: "#DBDCDE", // Focused border color
    },
  },
  "& .MuiSelect-select": {
    color: "#89868D", // Input text color
    display: "flex", // Align placeholder and selected text
    alignItems: "center", // Align items vertically
  },
  "& .MuiSelect-icon": {
    color: "#89868D", // Custom arrow icon color
    marginRight: "8px", // Space between icon and text
  },
}));

const SiDropdown = ({
  label,
  value,
  onChange,
  options,
  name,
  error,
  ...props
}) => {
  return (
    <CustomSelect
      value={value}
      onChange={onChange}
      IconComponent={KeyboardArrowDownIcon}
      label={label}
      {...props}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </CustomSelect>
  );
};

export default SiDropdown;
