import React from "react";
import { TextField } from "@mui/material";
import { fontSize, styled } from "@mui/system";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#F4F5F9",
    borderRadius: "4px",
    fontSize: "12px",

    "& fieldset": {
      borderColor: "#DBDCDE", // Default border color
    },
    "&:hover fieldset": {
      borderColor: "#DBDCDE", // Hover border color
    },
    "&.Mui-focused fieldset": {
      borderColor: "#DBDCDE", // Focused border color
    },
    "&.Mui-disabled fieldset": {
      borderColor: "transparent", // No border for disabled TextField
    },
  },
  "& .MuiInputBase-input": {
    color: "#89868D", // Input text color
    paddingTop: "7px",
    paddingBottom: "7px",
  },
}));

const SiTextField = ({
  placeholder,
  value,
  onChange,
  error,
  name,
  type = "text",
  ...props
}) => {
  return (
    <CustomTextField
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      type={type}
      name={name}
      error={error}
      size="small"
      fullWidth
      {...props}
    />
  );
};

export default SiTextField;
