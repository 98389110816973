import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import PrivateRoute from "./private-route";
import { Login } from "../components/login";
import Dashboard from "../components/dashboard";
import Media from "../components/media";
import Playlists from "../components/playlists";
import Stores from "../components/stores";
import Specifications from "../components/specs";
import Integrations from "../components/integrations";
import StoreGroup from "../components/store-group";
import Tags from "../components/tags";
const AppRoutes = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/login" element={<Login />}></Route>
          <Route exact path="/" element={<PrivateRoute />}>
            <Route exact path="/" element={<Dashboard />} />
            {/* <Route path="/" element={<Navigate to="/media" />} /> */}
            <Route exact path="/media" element={<Media />} />
            <Route exact path="/tags" element={<Tags />} />
            <Route exact path="/playlist" element={<Playlists />} />
            <Route exact path="/stores" element={<Stores />} />
            <Route exact path="/store-group" element={<StoreGroup />} />
            <Route exact path="/specs" element={<Specifications />} />
            <Route exact path="/integrations" element={<Integrations />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
};

export default AppRoutes;
