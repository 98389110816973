import React, { useEffect, useState, useRef } from "react";
import { Grid, TextField, Box, IconButton } from "@mui/material";
import "./index.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SiFileUpload from "../../core/file-uploader";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { toast } from "react-toastify";
import FormGenerator from "../../core/form-generator";
import { getMediaFormConfig, MEDIA_TYPE } from "./config";
import MediaPreview from "./media-preview";
import CustomButtonComponent from "../../core/custom-button";
import { handleFormValidation } from "../../utils";
const AddMedia = ({ setIsAddMedia, editObj, setEditObj, getMedia }) => {
  const [formData, setFormData] = useState({ tag: [] });
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [tagList, setTagList] = useState([]);
  const [isVerticleImage, setIsVerticleImage] = useState(false);

  const videoRef = useRef(null);
  const formDataHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((prevVal) => ({ ...prevVal, [name]: value }));
  };
  const handleVideoMetadata = (e) => {
    const duration = e.target.duration;
    setFormData((prevVal) => ({ ...prevVal, duration: Math.round(duration) }));
  };
  const getTagList = () => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.TAG}`)
      .then((response) => {
        if (!response.message) {
          if (Array.isArray(response) && response) {
            const result = response.map((ele) => ({
              label: ele.name,
              value: ele.id,
            }));
            setTagList(result);
          }
        }
      })
      .catch((err) => console.error(err));
  };
  const submitHandler = () => {
    const isValid = handleFormValidation(
      getMediaFormConfig(),
      setErrors,
      formData
    );
    if (isValid && !formData.mediaUrl) {
      toast.error("Media is required", { autoClose: 2000 });
      return;
    }
    if (isValid) {
      const payload = {};
      for (let ele in formData) {
        if (formData[ele]) {
          if (ele === "mediaType") {
            payload[ele] = formData[ele]?.value;
          } else if (ele === "tag") {
            payload[ele] = formData[ele]?.map((tag) => tag.value);
          } else {
            payload[ele] = formData[ele];
          }
        }
      }
      const params = {};
      if (payload.mediaType === "IMAGE" && isVerticleImage) {
        params["rotate"] = "true";
      }
      if (isEdit) {
        invokeApi(
          HTTP_METHODS.PUT,
          `${HOSTNAME}${REST_URLS.MEDIA}/${editObj?.id}`,
          payload,
          params
        )
          .then((res) => {
            if (res.message) {
              toast.error(res.message, { autoClose: 2000 });
            } else {
              toast.success("Media Updated Successfully", { autoClose: 2000 });
              setIsAddMedia(false);
              setEditObj({});
              getMedia({ limit: 10, page: 1, sortBy: "-createdAt" });
            }
          })
          .catch((err) => console.error(err));
      } else {
        invokeApi(
          HTTP_METHODS.POST,
          `${HOSTNAME}${REST_URLS.MEDIA}`,
          payload,
          params
        )
          .then((res) => {
            if (res.message) {
              toast.error(res.message, { autoClose: 2000 });
            } else {
              toast.success("Media Created Successfully", { autoClose: 2000 });
              setIsAddMedia(false);
              getMedia({ limit: 10, page: 1, sortBy: "-createdAt" });
            }
          })
          .catch((err) => console.error(err));
      }
    }
  };
  const setImageSize = (imageUrl) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      const isVerticle = img.height > img.width;
      setIsVerticleImage(isVerticle);
    };
  };

  useEffect(() => {
    if (formData.mediaUrl && formData.mediaType?.value === "IMAGE") {
      setImageSize(formData.mediaUrl);
    }
  }, [formData.mediaUrl]);
  useEffect(() => {
    if (formData?.mediaType?.value !== editObj?.mediaType) {
      setFormData((prevVal) => ({
        ...prevVal,
        mediaUrl: "",
        mediaUrl90: "",
        mediaUrl270: "",
      }));
    }
  }, [formData?.mediaType]);
  useEffect(() => {
    if (editObj?.id) {
      setIsEdit(true);
      let newTaglist = [];
      if (tagList.length) {
        newTaglist = tagList
          .map((ele) => editObj?.tag?.includes(parseInt(ele.value)) && ele)
          .filter((tag) => tag && tag);
      }

      setFormData({
        name: editObj?.name,
        description: editObj?.description,
        mediaType: MEDIA_TYPE.find(
          (media) => media.value === editObj?.mediaType
        ),
        duration: editObj?.duration,
        mediaUrl: editObj?.mediaUrl,
        tag: newTaglist,
        mediaUrl90: editObj?.mediaUrl90 || "",
        mediaUrl270: editObj?.mediaUrl270 || "",
      });
    }
  }, [tagList]);

  useEffect(() => {
    getTagList();
  }, []);
  return (
    <div>
      <Box sx={{ display: "flex", mt: 4, alignItems: "center" }}>
        <IconButton
          sx={{ p: 0, mr: 1 }}
          onClick={() => {
            setIsAddMedia(false);
            setEditObj({});
            setIsEdit(false);
            getMedia({ page: 1, limit: 10, sortBy: "-createdAt" });
          }}
        >
          <ArrowBackIcon />
        </IconButton>

        <p className="section-name">Media</p>
      </Box>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <div className="form-con">
            <p className="form-header">Media Form</p>
            <FormGenerator
              config={getMediaFormConfig(tagList)}
              formData={formData}
              setFormData={setFormData}
              errors={errors}
              setErrors={setErrors}
              fieldGridSize={6}
            />
            <div>
              {formData.mediaType?.value === "IMAGE" && (
                <div>
                  <p>Upload Image*</p>
                  <SiFileUpload
                    title="Upload Image"
                    url={`${REST_URLS.UPLOAD}`}
                    callBack={(newFile) => {
                      if (newFile.data.url) {
                        setFormData((prevVal) => ({
                          ...prevVal,
                          mediaUrl: newFile.data.url,
                        }));
                      }
                    }}
                    fileFormats={["image/jpeg", "image/png", "image/jpg"]}
                    accept="image/*"
                  />
                  <p>Or</p>
                  <TextField
                    label="Enter Image/Video URL"
                    size="small"
                    sx={{ width: "90%" }}
                    onChange={formDataHandler}
                    name="mediaUrl"
                    disabled={formData?.mediaUrl && !isEdit ? true : false}
                    value={formData?.mediaUrl || ""}
                  />
                </div>
              )}

              {formData.mediaType?.value === "VIDEO" && (
                <div>
                  <Grid container>
                    <Grid item md={4}>
                      <p>Upload Video*</p>
                      <SiFileUpload
                        title="Upload Video"
                        url={`${REST_URLS.UPLOAD}`}
                        callBack={(newFile) => {
                          if (newFile.data.url) {
                            setFormData((prevVal) => ({
                              ...prevVal,
                              mediaUrl: newFile.data.url,
                            }));
                          }
                        }}
                        fileFormats={["video/mp4", "video/webm"]}
                        accept="video/*"
                      />
                    </Grid>
                    <Grid item md={4}>
                      <p>Upload 90° Rotated Video*</p>
                      <SiFileUpload
                        title="Upload 90° Video"
                        url={`${REST_URLS.UPLOAD}`}
                        callBack={(newFile) => {
                          if (newFile.data.url) {
                            setFormData((prevVal) => ({
                              ...prevVal,
                              mediaUrl90: newFile.data.url,
                            }));
                          }
                        }}
                        fileFormats={["video/mp4", "video/webm"]}
                        accept="video/*"
                      />
                    </Grid>
                    <Grid item md={4}>
                      <p>Upload 270° Video*</p>
                      <SiFileUpload
                        title="Upload 270° Video"
                        url={`${REST_URLS.UPLOAD}`}
                        callBack={(newFile) => {
                          if (newFile.data.url) {
                            setFormData((prevVal) => ({
                              ...prevVal,
                              mediaUrl270: newFile.data.url,
                            }));
                          }
                        }}
                        fileFormats={["video/mp4", "video/webm"]}
                        accept="video/*"
                      />
                    </Grid>
                  </Grid>
                  <p>Or</p>
                  <TextField
                    label="Enter Video URL"
                    size="small"
                    fullWidth
                    onChange={formDataHandler}
                    sx={{ width: "90%", mt: 2 }}
                    name="mediaUrl"
                    disabled={formData?.mediaUrl && !isEdit ? true : false}
                    value={formData?.mediaUrl || ""}
                  />
                  <TextField
                    label="Enter 90° Video URL"
                    size="small"
                    fullWidth
                    onChange={formDataHandler}
                    sx={{ width: "90%", mt: 2 }}
                    name="mediaUrl90"
                    disabled={formData?.mediaUrl90 && !isEdit ? true : false}
                    value={formData?.mediaUrl90 || ""}
                  />
                  <TextField
                    label="Enter 270° Anticlockwise Video URL"
                    size="small"
                    fullWidth
                    onChange={formDataHandler}
                    sx={{ width: "90%", mt: 2 }}
                    name="mediaUrl270"
                    disabled={formData?.mediaUrl270 && !isEdit ? true : false}
                    value={formData?.mediaUrl270 || ""}
                  />
                </div>
              )}
            </div>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <CustomButtonComponent
                variant="contained"
                sx={{ width: "150px" }}
                onClick={submitHandler}
                color="success"
              >
                Submit
              </CustomButtonComponent>
            </Box>
          </div>
        </Grid>
        <Grid item md={6}>
          <div className="form-con">
            <p className="form-header">Media Preview</p>
            <MediaPreview
              mediaUrl={formData?.mediaUrl}
              mediaUrl90={formData?.mediaUrl90}
              mediaUrl270={formData?.mediaUrl270}
              handleVideoMetadata={handleVideoMetadata}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddMedia;
