import React from "react";
import { fontSize, styled } from "@mui/system";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
const CustomDateTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#F4F5F9",
    borderRadius: "4px",
    fontSize: "12px",
    "& fieldset": {
      borderColor: "#DBDCDE", // Default border color
    },
    "&:hover fieldset": {
      borderColor: "#DBDCDE", // Hover border color
    },
    "&.Mui-focused fieldset": {
      borderColor: "#DBDCDE", // Focused border color
    },
  },
  "& .MuiInputBase-input": {
    color: "#89868D", // Input text color
  },
}));
function CustomTextField(params) {
  return <CustomDateTextField size="small" fullWidth {...params} />;
}
const SiDatePicker = ({
  value = null,
  onChange,
  error,
  placeholder,
  minDate,
  ...props
}) => {
  let formattedDate = "";
  const formatVal = moment(value).format("DD-MM-YYYY");
  const inputFormat = "DD-MM-YYYY";
  const outputFormat = "DD/MM/YYYY";
  const isValid = dayjs(formatVal, inputFormat, true).isValid();
  if (isValid) {
    formattedDate = dayjs(formatVal, inputFormat).format(outputFormat);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        value={(isValid && dayjs(formattedDate, outputFormat)) || null}
        format="DD/MM/YYYY"
        onChange={onChange}
        minDate={minDate && minDate}
        {...props}
        slots={{
          textField: CustomTextField,
        }}
      />
    </LocalizationProvider>
  );
};

export default SiDatePicker;
