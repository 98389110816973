import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Autocomplete,
  TextField,
  InputAdornment,
  Chip,
  Stack,  
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import Chart from "react-apexcharts";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import StoreTwoToneIcon from "@mui/icons-material/StoreTwoTone";
import DesktopMacTwoToneIcon from "@mui/icons-material/DesktopMacTwoTone";
import AndroidIcon from "@mui/icons-material/Android";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import ScreenIcons from "./screen-icons";
import SlidePreview from "../../core/slide-preview";
import TIZEN_ICON from "../../resources/images/tizen.png";
const Dashboard = () => {
  const [data, setData] = useState({
    totalActiveStores: "",
    totalActiveScreens: "",
    totalAndroidScreens: "",
    totalTizenScreens: "",
    storeStats: {},
    specStats: []
  });
  const [selectedStore, setSelectedStore] = useState(null);
  const [inactiveScreens, setInactiveScreens] = useState(0);

  useEffect(() => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.DASHBOARD_DATA}`)
      .then((res) => {
        if (!res.message) {
          setData(res);
        }
      })
      .catch((err) => console.error(err));
  }, []);
  function getShortName(name) {
    if (name.length > 10) {
      return name.substring(0, 8) + "..."; // Show first 8 characters + '...'
    }
    return name;
  }
  const specChartData = {
    series: [
      {
        data: data?.specStats.map((spec) => spec.storeCount)
      }
    ],
    options: {
      chart: { type: "bar" },
      xaxis: {
        categories: data?.specStats.map((spec) =>
          getShortName(spec.specificationName)
        ),
        tooltip: {
          enabled: true,
          formatter: function (val, opts) {
            return data?.specStats[opts.dataPointIndex]?.specificationName;
          }
        }
      },
      tooltip: {
        enabled: true
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%"
        }
      },
      dataLabels: {
        enabled: false
      }
    }
  };
  const storeList = Object.keys(data?.storeStats || {});
  let first10Stores = [];
  if (+inactiveScreens > 0) {
    first10Stores = storeList
      .filter((store) => {
        if (inactiveScreens === 1 || inactiveScreens === 2) {
          return (
            data?.storeStats?.[store]?.inactiveScreens === +inactiveScreens
          );
        } else if (inactiveScreens === 3) {
          return data?.storeStats?.[store]?.inactiveScreens >= +inactiveScreens;
        }
      })
      .slice(0, 10);
  } else {
    first10Stores = storeList.slice(0, 10);
  }

  const stats = [
    {
      label: "Active Stores",
      value: data?.totalActiveStores || "0",
      description: "Last 30 days",
      icon: <StoreTwoToneIcon sx={{ color: "#ff9800" }} fontSize='large' />,
      borderClr: "#ff9800"
    },
    {
      label: "Active Screens",
      value: data?.totalActiveScreens || "0",
      description: "Per customer",
      borderClr: "#4caf50",
      icon: <DesktopMacTwoToneIcon sx={{ color: "#4caf50" }} fontSize='large' />
    },
    {
      label: "Android TV Screens",
      value: data?.totalAndroidScreens || "0",
      description: "Per day - Last 30 days",
      borderClr: "#4caf50",
      icon:<AndroidIcon fontSize='large' sx={{ color: "#4caf50" }} />       
    },
    {
      label: "Tizen Screens",
      value: data?.totalTizenScreens || "0",
      description: "Last 30 days",
      borderClr: "#9c27b0",
      icon: <img src={TIZEN_ICON} alt="tizen" style={{width:40}} />
    }
  ];
  const displayedStores = selectedStore
    ? [
        selectedStore,
        ...first10Stores.filter((store) => store !== selectedStore)
      ]
    : first10Stores;
  return (
    <Box p={3} pl={5}>
      <Typography variant='h5' fontWeight='bold'>
        Dashboard
      </Typography>
      <Grid
        container
        sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
        className='dashboard-container dashboard-tile'
      >
        {stats.map((stat, index) => (
          <Grid
            item
            md={2.5}
            key={index}
            sx={{
              display: "flex",
              justifyContent: "space-around",
              borderRight: index + 1 !== stats.length && "2px dashed #d2d1d1",
              paddingRight: "20px",
              boxSizing: "content-box"
            }}
          >
            <Box
              sx={{ border: `3px solid ${stat.borderClr}` }}
              className='dashboard-icon'
            >
              {stat.icon}
            </Box>
            <div className='stats-text-con'>
              <Typography className='dashboard-label'>{stat.label}</Typography>
              <Typography className='dashboard-value' variant='h6'>
                {stat.value}
              </Typography>
            </div>
          </Grid>
        ))}
      </Grid>

      <Card sx={{ mt: 5 }}>
        <CardContent>
          <Typography variant='h6'>Specification Statitics</Typography>
          <Chart
            options={specChartData.options}
            series={specChartData.series}
            type='bar'
            height={300}
          />
        </CardContent>
      </Card>

      <Box display='flex' gap={3} mt={3}>
        <Box
          sx={{
            flex: selectedStore ? 1 : 2,
            transition: "flex 0.3s ease"
          }}
        >
          <Card>
            <CardContent>
              <Typography variant='h6'>Store Statistics</Typography>
              <Typography variant='body2' color='gray'>
                Select a store to view details.
              </Typography>
              {/* Store Selection Dropdown */}
              <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
                <Box sx={{ width: "50%" }}>
                  <Autocomplete
                    options={storeList}
                    value={selectedStore}
                    onChange={(event, newValue) => setSelectedStore(newValue)}
                    clearOnEscape
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder='Search Store'
                        fullWidth
                        size='small'
                        variant='outlined'
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "25px",
                            boxShadow: "none",
                            "&.Mui-focused": {
                              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)"
                            }
                          }
                        }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <SearchIcon color='action' />
                            </InputAdornment>
                          ),
                          endAdornment: params.InputProps.endAdornment ? (
                            <InputAdornment position='end'>
                              {params.InputProps.endAdornment}
                            </InputAdornment>
                          ) : (
                            <InputAdornment position='end'>
                              <ClearIcon
                                color='action'
                                sx={{ cursor: "pointer" }}
                                onClick={() => setSelectedStore(null)}
                              />
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                </Box>
                <Box sx={{ minWidth: 180, ml: 2 }}>
                  <FormControl
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "25px",
                        boxShadow: "none",
                        "&.Mui-focused": {
                          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)"
                        }
                      }
                    }}
                  >
                    <InputLabel id='inactive-screens-select-label' size='small'>
                      Inactive Screens
                    </InputLabel>
                    <Select
                      labelId='inactive-screens-select-label'
                      id='inactive-screens-select'
                      value={inactiveScreens}
                      size='small'
                      label='Inactive Screens'
                      onChange={(event) =>
                        setInactiveScreens(event.target.value)
                      }
                    >
                      <MenuItem value={0}>None</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3+</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              {/* Store Cards */}
              <Grid container spacing={3}>
                {/* Show selected store at start if it's not in the top 10 stores */}
                {selectedStore && !first10Stores.includes(selectedStore) && (
                  <Grid item md={6} key={selectedStore}>
                    <Box
                      onClick={() => setSelectedStore(selectedStore)}
                      className='store-card selected-store'
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "#ffebf1", // Light pink background
                        border: "2px solid #ff4081", // Pink border
                        color: "#d81b60" // Pink text color
                      }}
                    >
                      <Typography variant='h6'>
                        Store {selectedStore}
                      </Typography>
                      <Stack
                        direction={selectedStore ? "column" : "row"}
                        spacing={1}
                        sx={{ mt: 1 }}
                      >
                        <ScreenIcons
                          androidCount={
                            data?.storeStats[selectedStore]?.android
                          }
                          tizenCount={data?.storeStats[selectedStore]?.tizen}
                        />
                        <Chip
                          label={`${data?.storeStats[selectedStore]?.activeScreens} Screens`}
                          variant='outlined'
                          color='secondary'
                          sx={{
                            fontWeight: "bold",
                            borderRadius: "16px",
                            borderColor: "#ff4081",
                            color: "white",
                            backgroundColor: "#fa699a"
                          }}
                        />
                      </Stack>
                    </Box>
                  </Grid>
                )}

                {/* Render first 10 stores */}
                {first10Stores.map((store) => {
                  return (
                    <Grid item md={selectedStore ? 6 : 4} key={store}>
                      <Box
                        onClick={() => setSelectedStore(store)}
                        className='store-card'
                        sx={{
                          cursor: "pointer",
                          backgroundColor:
                            selectedStore === store ? "#ffebf1" : "#fafafae2", // Highlight if selected
                          border:
                            selectedStore === store
                              ? "2px solid #ff4081"
                              : "1px solid #f8f8f8",
                          color: selectedStore === store ? "#d81b60" : "inherit"
                        }}
                      >
                        <Typography variant='h6'>Store {store}</Typography>
                        <Stack
                          direction={selectedStore ? "column" : "row"}
                          spacing={1}
                          sx={{ mt: 1 }}
                        >
                          <ScreenIcons
                            androidCount={data?.storeStats[store]?.android}
                            tizenCount={data?.storeStats[store]?.tizen}
                          />
                          <Chip
                            label={`${data?.storeStats[store]?.activeScreens} Screens`}
                            variant='outlined'
                            color='primary'
                            sx={{
                              fontWeight: "bold",
                              borderRadius: "16px",
                              borderColor: selectedStore === store && "#ff4081",
                              color: selectedStore === store && "white",
                              backgroundColor:
                                selectedStore === store && "#fa699a"
                            }}
                          />
                        </Stack>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </CardContent>
          </Card>
        </Box>

        {/* Media Section - Appears when a store is selected */}
        {selectedStore && (
          <Box
            sx={{
              flex: 1,
              transition: "flex 0.3s ease"
            }}
          >
            <Card sx={{ maxHeight: "900px", overflow: "auto" }}>
              <CardContent>
                <Typography variant='h6' sx={{ mb: 3 }}>
                  Current media in store {selectedStore}
                </Typography>
                <Grid container spacing={3}>
                  {Object.values(
                    data?.storeStats[selectedStore]?.mediaLists || {}
                  )
                    .flat()
                    .map((media, index) => (
                      <Grid
                        item
                        md={6}
                        key={index}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column"
                        }}
                      >
                        <SlidePreview
                          mediaList={[
                            {
                              mediaUrl: media.mediaUrl,
                              mediaType: media.mediaType,
                              duration: media.duration
                            }
                          ]}
                        />
                        <Typography variant='body2' sx={{ mt: 1 }}>
                          Screen ID:{" "}
                          {parseInt(
                            [...media?.name?.matchAll(/\d+/g)].pop(),
                            10
                          ) || 1}
                        </Typography>
                        <Typography
                          color='navy'
                          sx={{ mb: 3, wordBreak: "break-all" }}
                        >
                          {media?.name}
                        </Typography>
                      </Grid>
                    ))}
                </Grid>
              </CardContent>
            </Card>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
