import React, { useState, useEffect } from "react";
import "./index.scss";
const MediaPreview = ({
  mediaUrl,
  mediaUrl90,
  mediaUrl270,
  handleVideoMetadata,
}) => {
  const [isVertical, setIsVertical] = useState(false);
  const [isMedia90Vertical, setIsMedia90Vertical] = useState(false);
  const [isMedia270Vertical, setIsMedia270Vertical] = useState(false);

  const setImageSize = (mediaUrl, updateFuc) => {
    const img = new Image();
    img.src = mediaUrl;
    img.onload = () => {
      const isVerticle = img.height > img.width;
      updateFuc(isVerticle);
    };
  };
  const setVideoSize = (mediaUrl, updateFuc) => {
    const video = document.createElement("video");
    video.src = mediaUrl;
    video.onloadedmetadata = () => {
      const isVerticle = video.videoHeight > video.videoWidth;
      updateFuc(isVerticle);
    };
    video.onerror = (error) => {
      console.error("Error loading video:", error);
    };
  };
  useEffect(() => {
    setIsVertical(false);
    if (mediaUrl?.includes(".mp4") || mediaUrl?.includes(".webm")) {
      setVideoSize(mediaUrl, setIsVertical);
    } else {
      setImageSize(mediaUrl, setIsVertical);
    }
  }, [mediaUrl]);
  useEffect(() => {
    setIsMedia90Vertical(false);
    if (mediaUrl90?.includes(".mp4") || mediaUrl90?.includes(".webm")) {
      setVideoSize(mediaUrl90, setIsMedia90Vertical);
    } else {
      setImageSize(mediaUrl90, setIsMedia90Vertical);
    }
  }, [mediaUrl90]);
  useEffect(() => {
    setIsMedia270Vertical(false);
    if (mediaUrl270?.includes(".mp4") || mediaUrl270?.includes(".webm")) {
      setVideoSize(mediaUrl270, setIsMedia270Vertical);
    } else {
      setImageSize(mediaUrl270, setIsMedia270Vertical);
    }
  }, [mediaUrl270]);

  return (
    <div className="preview-tv-con">
      <div
        className={
          isVertical ? "vertical-television-screen" : "television-screen"
        }
        style={{ minWidth: isVertical ? "300px" : "500px" }}
      >
        <div>
          {mediaUrl ? (
            <div className="previewCon white-bg">
              {mediaUrl?.includes(".mp4") || mediaUrl?.includes(".webm") ? (
                <video
                  // ref={videoRef}
                  src={mediaUrl}
                  controls
                  className="preview-video-size"
                  onLoadedMetadata={(e) => handleVideoMetadata(e)}
                />
              ) : (
                <img
                  src={mediaUrl}
                  alt="Media Preview"
                  className="preview-img-size"
                />
              )}
            </div>
          ) : (
            <div className="previewCon">
              <p>No Media</p>
            </div>
          )}
        </div>
      </div>
      {mediaUrl90 && (
        <div
          className={
            isMedia90Vertical
              ? "vertical-television-screen"
              : "television-screen"
          }
          style={{ minWidth: isMedia90Vertical ? "300px" : "500px" }}
        >
          <div className="previewCon white-bg">
            {mediaUrl90?.includes(".mp4") || mediaUrl90?.includes(".webm") ? (
              <video
                src={mediaUrl90}
                controls
                autoPlay
                className="preview-video-size"
                onLoadedMetadata={(e) => handleVideoMetadata(e)}
              />
            ) : (
              <img
                src={mediaUrl90}
                alt="Media Preview"
                className="preview-img-size"
              />
            )}
          </div>
        </div>
      )}
      {mediaUrl270 && (
        <div
          className={
            isMedia270Vertical
              ? "vertical-television-screen"
              : "television-screen"
          }
          style={{ minWidth: isMedia270Vertical ? "300px" : "500px" }}
        >
          <div className="previewCon white-bg">
            {mediaUrl270?.includes(".mp4") || mediaUrl270?.includes(".webm") ? (
              <video
                src={mediaUrl270}
                controls
                autoPlay
                className="preview-video-size"
                onLoadedMetadata={(e) => handleVideoMetadata(e)}
              />
            ) : (
              <img
                src={mediaUrl270}
                alt="Media Preview"
                className="preview-img-size"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MediaPreview;
