import React, { useState, useEffect } from "react";
import "./nav.scss";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Typography,
  Box,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { SIDE_MENU } from "./config";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import { clearOfflineData, getOfflineData } from "../../utils/offline-services";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
const SideMenu = () => {
  const [openMenu, setOpenMenu] = useState({});
  const [tabsList, setTabsList] = useState([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleMenuClick = (label) => {
    setOpenMenu((prevOpenMenu) => ({
      ...prevOpenMenu,
      [label]: !prevOpenMenu[label], // Toggle the clicked menu
    }));
  };

  const logout = () => {
    const refreshToken = getOfflineData("tokens")?.refresh?.token || "";
    invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${REST_URLS.LOGOUT}`, {
      refreshToken,
    })
      .then(() => {
        clearOfflineData("user");
        navigate("/login");
      })
      .catch((err) => {
        clearOfflineData("user");
        navigate("/login");
      });
  };

  const redirectToRoute = (url, parentLabel) => {
    navigate(url);
    if (parentLabel) {
      setOpenMenu((prevOpenMenu) => ({
        ...prevOpenMenu,
        [parentLabel]: true, // Ensure parent remains expanded
      }));
    }
  };

  const renderSubMenu = (submenu, parentLabel) => (
    <List component="div" disablePadding>
      {submenu
        .filter((subItem) => !subItem.disabled) // Exclude disabled items
        .map((subItem, index) => (
          <div
            key={index}
            className={
              pathname === subItem.url
                ? "sub-menu-item menu-item active-list"
                : "sub-menu-item menu-item"
            }
            onClick={() => redirectToRoute(subItem.url, parentLabel)}
          >
            <ListItem sx={{ padding: 0.5, pl: 5, pr: 1.5 }}>
              <ListItemText primary={subItem.name} />
            </ListItem>
          </div>
        ))}
    </List>
  );

  useEffect(() => {
    const tabs = SIDE_MENU();
    setTabsList(tabs);

    tabs.forEach((menu) => {
      if (menu.children?.some((subItem) => subItem.url === pathname)) {
        setOpenMenu((prevOpenMenu) => ({
          ...prevOpenMenu,
          [menu.name]: true, // Expand parent menu
        }));
      }
    });
  }, [pathname]); // Ensure it runs when pathname changes

  return (
    <div className="side-menu-con">
      <div className="side-menu-header">
        <img
          src={require("../../resources/images/logo.png")}
          className="logo-img"
          alt="Logo"
        />
      </div>
      <List>
        <div className="menu-items">
          {tabsList.map((item) => (
            <div
              key={item.name}
              className={
                pathname === item.url ||
                (item.children?.some((sub) => sub.url === pathname) &&
                  !item.children)
                  ? "menu-item active-list"
                  : item.children
                  ? " no-hover"
                  : "menu-item"
              }
              onClick={
                item.children
                  ? () => handleMenuClick(item.name)
                  : () => redirectToRoute(item?.url)
              }
            >
              <ListItem sx={{ padding: 0.5, pl: 1.5, pr: 1.5 }}>
                {item.icon && item.icon}
                <ListItemText primary={item.name} sx={{ ml: 1 }} />
                {item.children && (
                  <IconButton edge="end">
                    {openMenu[item.name] ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                )}
              </ListItem>

              {item.children && (
                <Collapse in={openMenu[item.name]} timeout="auto" unmountOnExit>
                  {renderSubMenu(item.children, item.name)}
                </Collapse>
              )}
            </div>
          ))}
        </div>
      </List>
      <Box
        sx={{
          position: "absolute",
          bottom: "15px",
          width: "100%",
          overflow: "hidden",
          cursor: "pointer",
        }}
      >
        <Button
          color="error"
          fullWidth
          sx={{ mr: 2, ml: 2 }}
          onClick={logout}
          startIcon={<LogoutTwoToneIcon />}
        >
          Logout
        </Button>
      </Box>
    </div>
  );
};

export default SideMenu;
