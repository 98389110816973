import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { fontSize, styled } from "@mui/system";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Custom Styled Component for the Autocomplete
const CustomAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiInputBase-root": {
    backgroundColor: "#F4F5F9",
    borderRadius: "4px",
    fontSize: "12px",
    "& fieldset": {
      borderColor: "#DBDCDE", // Default border color
    },
    "&:hover fieldset": {
      borderColor: "#DBDCDE", // Hover border color
    },
    "&.Mui-focused fieldset": {
      borderColor: "#DBDCDE", // Focused border color
    },
    "&.Mui-disabled fieldset": {
      borderColor: "transparent", // No border for disabled TextField
    },
  },
  "& .MuiInputBase-input": {
    color: "#89868D", // Input text color
    display: "flex",
    alignItems: "center", // Align items vertically
  },

  "& .MuiAutocomplete-popupIndicator": {
    color: "#89868D", // Custom arrow icon color
    marginRight: "8px", // Space between icon and text
  },
}));

const SiAutocomplete = ({
  label,
  value,
  onChange,
  options,
  placeholder,
  error,
  optionLabel,
  multiple,
  ...props
}) => {
  return (
    <CustomAutocomplete
      value={value || null}
      multiple={multiple ? multiple : false}
      onChange={(event, newValue) => onChange(newValue)}
      options={options}
      popupIcon={<KeyboardArrowDownIcon />}
      getOptionLabel={(option) =>
        optionLabel ? option[optionLabel] : option.label || ""
      }
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          error={error}
          size="small"
        />
      )}
      {...props}
    />
  );
};

export default SiAutocomplete;
