import React from "react";
import { TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const SiDateTimePicker = ({ value, onChange, error, placeholder, ...props }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DateTimePicker
         renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              error={error}
              fullWidth
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#F4F5F9",
                  borderRadius: "8px",
                  "& fieldset": {
                    borderColor: "#DBDCDE", // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#DBDCDE", // Hover border color
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#DBDCDE", // Focused border color
                  },
                },
                "& .MuiInputBase-input": {
                  padding: '8.5px 14px !important', // Directly apply padding to input
                  color: "red", // Input text color
                },
              }}
              InputLabelProps={{
                shrink: false, // Prevents the label from shrinking
                style: { display: "none" }, // Hides the label completely
              }}
            />
          )}      
        label=""
        onChange={onChange}
        value={dayjs(value) || null}     
        inputFormat="DD-MM-YYYY HH:mm" 
        {
            ...props
        }        
    />
    </LocalizationProvider>
  );
};

export default SiDateTimePicker;
