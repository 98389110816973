import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DragDropContext } from "react-beautiful-dnd";
import MediaSelector from "./mediaCard";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import CustomButtonComponent from "../../core/custom-button";
import dayjs from "dayjs";
import SlidePreview from "../../core/slide-preview";
import { toast } from "react-toastify";

import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
const TimewisePlaylist = ({ timeRangeMediaList, setTimeRangeMediaList }) => {
  const [allMedia, setAllMedia] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [activeMedia, setActiveMedia] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [activeChip, setActiveChip] = useState("");
  const getMedia = (params) => {
    plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.MEDIA}`, null, params)
      .then((response) => {
        if (response) {
          const activeMediaIds = activeMedia.map((media) => media.id);
          const newMediaItems = response.results.filter(
            (media) => !activeMediaIds.includes(media.id)
          );
          setAllMedia(newMediaItems);
        } else {
          console.error("Fetching Media failed");
        }
      })
      .catch((err) => {
        console.error("Fetching Media failed");
      });
  };
  const onDragEnd = (result) => {
    setRefresh(Math.random());

    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let add;
    let allMediaList = allMedia;
    let activeMediaList = activeMedia;
    // Source Logic
    if (source.droppableId === "ALL_MEDIA") {
      add = allMediaList[source.index];
      allMediaList.splice(source.index, 1);
    } else {
      add = activeMediaList[source.index];
      activeMediaList.splice(source.index, 1);
    }

    // Destination Logic
    if (destination.droppableId === "ALL_MEDIA") {
      allMediaList.splice(destination.index, 0, add);
    } else {
      activeMediaList.splice(destination.index, 0, add);
    }

    setActiveMedia(activeMediaList);
    setAllMedia(allMediaList);
  };
  const formatTime = (value) => dayjs(value).format("HH:mm");
  const clearHandler = () => {
    setActiveMedia([]);
    setStartTime(null);
    setEndTime(null);
    getMedia({ page: 1, limit: 50, sortBy: "-createdAt" });
  };
  const submitHandler = () => {
    if (!startTime || !endTime) {
      toast.error("Start and End Time is required");
      return;
    }
    if (activeMedia.length < 1) {
      toast.error("Atleast one media is required for creating Playlist");
      return;
    }
    setTimeRangeMediaList((prevVal) => [
      ...prevVal,
      { startTime: startTime, endTime: endTime, mediaList: activeMedia },
    ]);

    clearHandler();
  };

  useEffect(() => {
    getMedia({ page: 1, limit: 50, sortBy: "-createdAt" });
  }, [activeMedia]);
  return (
    <div className="page-body">
      <Grid container spacing={3}>
        <Grid item md={7}>
          <div className="form-con">
            <p className="form-header">Timewise Playlist Form</p>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker", "TimePicker"]}>
                  <TimePicker
                    label="Start Time"
                    value={startTime ? dayjs(startTime, "HH:mm") : null}
                    onChange={(newValue) => {
                      const formattedTime = formatTime(newValue);
                      setStartTime(formattedTime);
                    }}
                    views={["hours", "minutes"]}
                    format="HH:mm"
                  />
                  <TimePicker
                    label="End Time"
                    value={endTime ? dayjs(endTime, "HH:mm") : null}
                    onChange={(newValue) => {
                      const formattedTime = formatTime(newValue);
                      setEndTime(formattedTime);
                    }}
                    views={["hours", "minutes"]}
                    format="HH:mm"
                  />
                </DemoContainer>
              </LocalizationProvider>
              <CustomButtonComponent
                variant="contained"
                color="success"
                sx={{ mr: 3 }}
                onClick={submitHandler}
              >
                Add
              </CustomButtonComponent>
            </Box>
          </div>
          <div className="form-con">
            <DragDropContext onDragEnd={onDragEnd}>
              <MediaSelector
                allMedia={allMedia}
                activeMedia={activeMedia}
                getMedia={getMedia}
              />
            </DragDropContext>
          </div>
        </Grid>
        <Grid item md={5}>
          <div className="form-con">
            <p className="form-header">Playlist Preview</p>
            <div>
              {activeMedia.length > 0 ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <SlidePreview mediaList={activeMedia} />
                </Box>
              ) : (
                <div className="television-screen">
                  <div className="previewCon">
                    <p>Preview</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          {timeRangeMediaList.length > 0 && (
            <div className="form-con">
              <div className="time-range-con">
                {timeRangeMediaList?.map((time, index) => {
                  return (
                    <div
                      className={`chip-com-con ${
                        activeChip === index ? "active-time-range" : "purple-bg"
                      }`}
                      style={{ width: "fit-content", marginRight: "10px " }}
                      onClick={() => {
                        setActiveMedia(time.mediaList);
                        setActiveChip(index);
                      }}
                    >
                      <p>
                        {time.startTime} - {time.endTime}
                      </p>
                      <DeleteTwoToneIcon
                        onClick={() => {
                          const filteredList = timeRangeMediaList?.filter(
                            (item, i) => i !== index
                          );
                          setTimeRangeMediaList(filteredList);
                        }}
                        fontSize="small"
                        color="error"
                        sx={{ ml: 1, cursor: "pointer" }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default TimewisePlaylist;
