import React, { useState, useEffect, useCallback } from "react";
import "./index.scss";
import Particles from "react-tsparticles";
import { useNavigate } from "react-router-dom/dist";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { getOfflineData, setOfflineData } from "../../utils/offline-services";
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import { loadFull } from "tsparticles";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import DesktopWindowsTwoToneIcon from "@mui/icons-material/DesktopWindowsTwoTone";
export const Login = () => {
  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const changeHandler = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setLoginDetails((prevVal) => ({
      ...prevVal,
      [name]: value,
    }));
  };
  const loginFormSubmitHandler = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.LOGIN}`,
      loginDetails
    ).then((res) => {
      if (res.message) {
        toast.error(res.message, { autoClose: 2000 });
      } else {
        res.user && setOfflineData("user", res.user);
        res.tokens && setOfflineData("tokens", res.tokens);
        navigate("/");
      }
    });
  };
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  useEffect(() => {
    const isUser = getOfflineData("user");
    if (isUser) {
      navigate("/");
    }
  }, []);
  return (
    <div>
      <div className="login-container">
        <div className="left">
          <div className="login-form">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <DesktopWindowsTwoToneIcon
                fontSize="large"
                color="#102f77"
                sx={{ color: "#102f77" }}
              /> */}
              <img
                src={require("../../resources/images/logo.png")}
                className="img"
              />
              <Typography variant="h4" sx={{ color: "#072b7a" }}>
                Signage
              </Typography>
            </Box>

            <div className="title">Login</div>

            <TextField
              label="Email"
              size="small"
              name="email"
              required
              onChange={changeHandler}
            />
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              size="small"
              name="password"
              required
              onChange={changeHandler}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="outlined"
              fullWidth
              className="loginButton"
              onClick={loginFormSubmitHandler}
              disabled={
                !loginDetails.email || !loginDetails.password ? true : false
              }
            >
              Login
            </Button>
          </div>
        </div>
        <div className="right"></div>
      </div>
    </div>
  );
};
