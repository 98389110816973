import React, { useEffect, useState } from "react";
import "../index.scss";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { HTTP_METHODS, invokeApi, plainApi } from "../../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../../utils/endpoints";
import SlidePreview from "../../../core/slide-preview";
const Screens = ({ formData, setFormData, isEdit }) => {
  const [screenData, setScreenData] = useState([]);
  const [playlistList, setPlaylistList] = useState([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState([]);
  const [orientationList, setOrientationList] = useState([
    { label: "Horizontal", value: "HORIZONTAL" },
    { label: "Vertical", value: "VERTICAL" },
  ]);
  const getPlaylistList = (value) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.SEARCH_PLAYLIST}/${value}`
      )
        .then((res) => {
          const result = res.map((ele) => ({
            ...ele,
            label: ele.name,
            value: ele.id,
          }));
          setPlaylistList(result);
        })
        .catch((err) => console.error(err));
    }, 100);
  };
  const onInputChange = (event, value) => {
    if (value?.trim()) {
      getPlaylistList(value);
    }
  };

  useEffect(() => {
    if (
      formData.noOfScreens > 0 &&
      formData.noOfScreens !== formData?.screenDetails?.length
    ) {
      const arr = Array.from({ length: formData.noOfScreens }, () => ({
        screenId: "",
        orientation: "",
        playlist: "",
      }));
      setScreenData(arr);
    }
    if (
      formData?.screenDetails?.length > 0 &&
      formData.noOfScreens === formData?.screenDetails?.length
    ) {
      setScreenData(formData.screenDetails);
    }
  }, []);
  useEffect(() => {
    getPlaylistList();
  }, []);
  useEffect(() => {
    setFormData({ ...formData, screenDetails: screenData });
  }, [screenData]);
  return (
    <div>
      <Grid
        container
        md={12}
        spacing={3}
        sx={{ display: "flex", justifyContent: "center", mt: 3 }}
      >
        {screenData.length > 0 ? (
          screenData.map((screen, index) => {
            let playlistMedia;
            if (typeof screen.playlist === "string") {
              playlistMedia = selectedPlaylist?.find(
                (ele) => ele.id === screen.playlist
              );
            } else {
              playlistMedia = screen.playlist;
            }

            return (
              <Grid
                item
                md={3}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <div className="screen-card">
                  {screen.playlist ? (
                    <SlidePreview
                      mediaList={playlistMedia ? playlistMedia.mediaList : []}
                      orientation={
                        screen.orientation === "VERTICAL" && screen.orientation
                      }
                    />
                  ) : (
                    <div className="television-screen">
                      <div className="previewCon">
                        <p>Preview</p>
                      </div>
                    </div>
                  )}

                  <Autocomplete
                    fullWidth
                    options={playlistList}
                    defaultValue={(isEdit && screen?.playlist?.name) || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Playlist"
                        size="small"
                        sx={{
                          mt: 2,
                          mb: 1.5,
                          ml: 0.5,
                          backgroundColor: "white",
                          width: "95%",
                        }}
                      />
                    )}
                    onInputChange={onInputChange}
                    onChange={(e, value) => {
                      const arr = [...screenData];
                      if (value?.value) {
                        setSelectedPlaylist((prevVal) => [...prevVal, value]);
                        arr[index] = { ...arr[index], playlist: value?.value };
                        setScreenData(arr);
                      } else {
                        arr[index] = { ...arr[index], playlist: "" };
                        setScreenData(arr);
                      }
                    }}
                  />

                  <TextField
                    label="ID"
                    size="small"
                    sx={{
                      backgroundColor: "white",
                      mr: 0.5,
                      width: "95%",
                    }}
                    value={screen?.screenId}
                    fullWidth
                    onChange={(e) => {
                      const arr = [...screenData];
                      arr[index] = {
                        ...arr[index],
                        screenId: e?.target?.value,
                      };
                      setScreenData(arr);
                    }}
                  />
                  <Autocomplete
                    fullWidth
                    options={orientationList}
                    defaultValue={(isEdit && screen.orientation) || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Orientation"
                        size="small"
                        sx={{
                          mt: 1.5,
                          mb: 1.5,
                          ml: 0.5,
                          backgroundColor: "white",
                          width: "95%",
                        }}
                      />
                    )}
                    onChange={(e, value) => {
                      const arr = [...screenData];
                      if (value?.value) {
                        arr[index] = {
                          ...arr[index],
                          orientation: value?.value,
                        };
                        setScreenData(arr);
                      } else {
                        arr[index] = { ...arr[index], orientation: "" };
                        setScreenData(arr);
                      }
                    }}
                  />
                </div>
              </Grid>
            );
          })
        ) : (
          <Typography sx={{ mt: 3, mb: 3 }} variant="h5">
            Please Enter Number of screens
          </Typography>
        )}
      </Grid>
    </div>
  );
};

export default Screens;
