import React from "react";
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Table,
  TableBody,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const StoresList = ({ data, onClick }) => {
  return (
    <div>
      {" "}
      <Box sx={{ margin: 1, mb: 5 }}>
        <Table size="small" aria-label="purchases">
          <TableHead sx={{ background: "#1877f214" }}>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Store Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Store Code</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Description</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Area</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>City</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((store) => (
              <TableRow key={store.id}>
                <TableCell component="th" scope="row">
                  {store.name}
                </TableCell>
                <TableCell>{store.storeCode}</TableCell>
                <TableCell>{store.description}</TableCell>
                <TableCell>{store.area}</TableCell>
                <TableCell>{store.city}</TableCell>
                <TableCell>
                  <EditIcon
                    onClick={() => onClick(store, "edit")}
                    sx={{ cursor: "pointer" }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </div>
  );
};

export default StoresList;
