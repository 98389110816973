import React, { useState, useEffect, useReducer } from "react";
import "./index.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  Collapse,
  IconButton,
  Paper,
  Pagination,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const MuiTable = ({
  dataList,
  columnsList,
  pageCount,
  onChange,
  filters,
  onClick,
  maxWords,
  customSiRowClass = "",
  showAllFunc,
  customTableClass,
  customTableConClass,
  renderCollapseContent, // Function to render collapsible content
}) => {
  const [page, setPage] = useState(1);

  // Reducer for managing collapsed rows state
  const [openRows, toggleRow] = useReducer((state, id) => {
    return { ...state, [id]: !state[id] };
  }, {});

  useEffect(() => {
    if (Number.isInteger(parseInt(filters?.page))) {
      setPage(filters.page);
    }
  }, [filters]);

  function truncateWords(text, maxWords) {
    const words = text?.split(" ");
    if (words.length <= maxWords) {
      return text;
    }
    return words.slice(0, maxWords).join(" ") + "...";
  }

  return (
    <div className={`custom-mui-table ${customTableClass || ""}`}>
      <div className={`mui-table-with-scroll ${customTableConClass || ""}`}>
        <div className={`con-scroll`}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} stickyHeader>
              <TableHead>
                <TableRow className="custom-table-header">
                  {renderCollapseContent && (
                    <TableCell className="custom-table-header" />
                  )}
                  {columnsList?.map((head) => (
                    <TableCell
                      key={head.id}
                      align="center"
                      className="custom-table-header"
                    >
                      {head.headerRender ? head.headerRender() : head.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataList?.map((row, index) => {
                  const rowId = row.id || index;
                  const isOpen = openRows[rowId];

                  return (
                    <React.Fragment key={rowId}>
                      <TableRow>
                        {renderCollapseContent && (
                          <TableCell>
                            <IconButton
                              size="small"
                              onClick={() => toggleRow(rowId)}
                              aria-expanded={isOpen}
                            >
                              {isOpen ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>
                        )}

                        {columnsList?.map((col) => {
                          let value = row[col.id];
                          if (typeof col?.valueConversionLogic === "function") {
                            value = col.valueConversionLogic(value);
                          }
                          if (col.render) {
                            return (
                              <TableCell key={col.id} align="center">
                                {col.render(row, onClick, index)}
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell key={col.id} align="center">
                              {maxWords &&
                              `${value}`?.split(" ").length > maxWords ? (
                                <p className="read-more-text">
                                  {truncateWords(value, maxWords)}{" "}
                                  <span
                                    className="show-more"
                                    onClick={() =>
                                      showAllFunc({ isOpen: true, text: value })
                                    }
                                  >
                                    Read More
                                  </span>
                                </p>
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                      {renderCollapseContent && (
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={columnsList.length + 1}
                          >
                            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                              {renderCollapseContent(row)}
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="si-pagination">
          {pageCount > 1 && (
            <Pagination
              page={page}
              count={pageCount}
              className="custom-pagination"
              color="primary"
              onChange={(e, page) => {
                setPage(page);
                if (onChange) {
                  onChange(page);
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MuiTable;
