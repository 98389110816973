import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import SummarizeIcon from "@mui/icons-material/Summarize";
import LandscapeIcon from "@mui/icons-material/Landscape";
import PlaylistPlayIcon from "@mui/icons-material/PlaylistPlay";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";

export const SIDE_MENU = () => [
  {
    name: "Dashboard",
    url: "/",
    value: "/",
    icon: <DashboardIcon sx={{ mr: 1 }} />,
    mapkey: 1,
  },
  {
    name: "Media",
    url: "/media",
    value: "/media",
    icon: <LandscapeIcon sx={{ mr: 1 }} />,
    id: 2,
  },
  {
    name: "Playlists",
    url: "/playlist",
    value: "/playlist",
    icon: <PlaylistPlayIcon sx={{ mr: 1 }} />,
    id: 3,
  },
  {
    name: "Stores",
    icon: <StorefrontRoundedIcon sx={{ mr: 1 }} />,
    id: 4,
    url: "/store-group",
  },
  // {
  //   label: "Store Group",
  //   url: "/store-group",
  //   value: "/store-group",
  //   logo: <StorefrontRoundedIcon sx={{ mr: 1 }} />,
  //   mapkey: 4,
  // },
  {
    name: "Specifications",
    url: "/specs",
    value: "/specs",
    icon: <SummarizeIcon sx={{ mr: 1 }} />,
    id: 5,
  },
  {
    name: "Tags",
    url: "/tags",
    value: "/tags",
    icon: <SummarizeIcon sx={{ mr: 1 }} />,
    id: 6,
  },
  // {
  //   label: "Integrations",
  //   url: "/integrations",
  //   value: "/integrations",
  //   logo: <IntegrationInstructionsIcon sx={{ mr: 1 }} />,
  //   mapkey: 2,
  // },
];
