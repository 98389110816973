import React, { useState, useEffect } from "react";
import "./index.scss";
import MuiTable from "../../core/mui-table";
import { getHeaderConfig } from "./config";
import { Button, Grid, TextField, Typography } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddMedia from "./addMedia";
import CustomModal from "../../core/modal";
import SearchIcon from "@mui/icons-material/Search";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";
import MediaPreview from "./media-preview";

const Media = () => {
  const [tableData, setTableData] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt"
  });
  const [searchMedia, setSearchMedia] = useState("");
  const [isAddMedia, setIsAddMedia] = useState(false);
  const [editObj, setEditObj] = useState({});
  const [isDelete, setIsDelete] = useState({
    isOpen: false,
    data: {}
  });
  const [preView, setPreview] = useState({
    isOpen: false,
    data: {}
  });
  const clickHandler = (data, type) => {
    switch (type) {
      case "preview":
        setPreview({
          isOpen: true,
          data
        });
        break;
      case "edit":
        setIsAddMedia(true);
        setEditObj(data);
        break;
      case "delete":
        setIsDelete({
          isOpen: true,
          data
        });
        break;
      default:
        break;
    }
  };
  const deleteHandler = () => {
    invokeApi(
      HTTP_METHODS.DELETE,
      `${HOSTNAME}${REST_URLS.MEDIA}/${isDelete.data.id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          toast.success("Media Deleted Successfully", { autoClose: 2000 });
          setIsDelete({
            isOpen: false,
            data: {}
          });
          getMedia(filters);
        }
      })
      .catch((err) => console.error(err));
  };
  const getMedia = () => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.MEDIA}`, null, filters)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setTableData(res);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleVideoMetadata = (e) => {
    const duration = e.target.duration;
  };

  useEffect(() => {
    getMedia();
  }, [filters]);

  return (
    <div className='page-body'>
      {!isAddMedia && (
        <div className='media-con'>
          <h1 className='header'>Media</h1>
          <Grid container md={12} sx={{ mb: 2 }}>
            <Grid item md={8} sx={{ display: "flex", alignItems: "center" }}>
              <Grid item md={6} sx={{ mr: 2 }}>
                <TextField
                  label='Search Media'
                  size='small'
                  fullWidth
                  value={searchMedia}
                  onChange={(event) => setSearchMedia(event.target.value)}
                />
              </Grid>
              <Grid item md={3}>
                <Button
                  variant='outlined'
                  color='success'
                  startIcon={<SearchIcon />}
                  onClick={() =>
                    setFilters({
                      ...filters,
                      name: searchMedia,
                      page: 1
                    })
                  }
                >
                  Search
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              md={4}
              sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}
            >
              <Button
                variant='outlined'
                size='small'
                startIcon={<AddOutlinedIcon />}
                onClick={() => setIsAddMedia(true)}
              >
                Add Media
              </Button>
            </Grid>
          </Grid>

          <MuiTable
            columnsList={getHeaderConfig()}
            dataList={tableData?.results || []}
            filters={filters}
            onClick={clickHandler}
            pageCount={tableData?.totalPages}
            onChange={(page) => {
              setFilters({
                ...filters,
                page
              });
              getMedia({ ...filters, page });
            }}
          />
        </div>
      )}
      {isAddMedia && (
        <AddMedia
          setIsAddMedia={setIsAddMedia}
          editObj={editObj}
          setEditObj={setEditObj}
          getMedia={getMedia}
        />
      )}
      {preView?.isOpen && (
        <CustomModal onClose={() => setPreview({ isOpen: false, data: {} })}>
          <Typography>{preView?.data?.name}</Typography>
          <div className='modal-preview'>
            <MediaPreview
              mediaUrl={preView.data.mediaUrl}
              handleVideoMetadata={handleVideoMetadata}
            />
            {/* {preView.data.mediaUrl.includes(".mp4") ||
            preView.data.mediaUrl.includes(".webm") ? (
              <video
                src={preView.data.mediaUrl}
                controls
                className="preview-video-size"
              />
            ) : (
              <img
                src={preView.data.mediaUrl}
                alt="Media Preview"
                className="preview-img-size"
              />
            )} */}
          </div>
        </CustomModal>
      )}
      {isDelete?.isOpen && (
        <CustomModal
          title='Delete Media'
          onClose={() => {
            setIsDelete({
              isOpen: false,
              data: {}
            });
          }}
        >
          <h1>Are You Sure?</h1>
          <Button variant='contained' color='error' onClick={deleteHandler}>
            Delete
          </Button>
        </CustomModal>
      )}
    </div>
  );
};

export default Media;
