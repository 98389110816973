import React, { useState, useEffect } from "react";
import "./index.scss";
const SlidePreview = ({ mediaList = [], orientation = "" }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVertical, setIsVertical] = useState(false);
  const setImageSize = (mediaUrl) => {
    const img = new Image();
    img.src = mediaUrl;
    img.onload = () => {
      const isVerticle = img.height > img.width;
      setIsVertical(isVerticle);
    };
  };
  const setVideoSize = (mediaUrl) => {
    const video = document.createElement("video");
    video.src = mediaUrl;
    video.onloadedmetadata = () => {
      const isVerticle = video.videoHeight > video.videoWidth;
      setIsVertical(isVerticle);
    };
    video.onerror = (error) => {
      console.error("Error loading video:", error);
    };
  };
  const getOrientation = (url) => {
    setIsVertical(false);
    if (url?.includes(".mp4") || url?.includes(".webm")) {
      setVideoSize(url);
    } else {
      setImageSize(url);
    }
  };

  useEffect(() => {
    if (mediaList.length > 0) {
      getOrientation(mediaList[0]?.mediaUrl || "");
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % mediaList.length);
      }, mediaList[currentIndex]?.duration * 1000);

      return () => clearInterval(interval);
    }
  }, [currentIndex, mediaList]);
  return (
    <div
      className={
        orientation
          ? orientation === "VERTICAL"
            ? "vertical-television-screen"
            : "television-screen"
          : isVertical
          ? "vertical-television-screen"
          : "television-screen"
      }
    >
      {mediaList?.map((media, index) => (
        <div
          key={media?.id || index}
          style={{ display: index === currentIndex ? "block" : "none" }}
        >
          {media?.mediaType === "VIDEO" ? (
            <video
              autoPlay
              muted
              loop
              playsInline
              src={media?.mediaUrl}
              className="preview-video-size"
            />
          ) : (
            <img
              src={media?.mediaUrl}
              alt={media?.name}
              className="preview-img-size"
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default SlidePreview;
