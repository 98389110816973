import React, { useState, useRef, useEffect } from "react";
import "./index.scss";
import {
  Grid,
  TextField,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Box,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";
import SlidePreview from "../../core/slide-preview";
import MediaSelector from "./mediaCard";
import { DragDropContext } from "react-beautiful-dnd";
import TimewisePlaylist from "./timewisePlaylist";
import DatewisePlaylist from "./dateWisePlaylist";
const AddPlaylist = ({
  setIsAddPlaylist,
  editObj,
  setEditObj,
  getPlaylist,
}) => {
  const [formData, setFormData] = useState({});
  const [mode, setMode] = useState("default");
  const [isEdit, setIsEdit] = useState(false);
  const [timeRangeMediaList, setTimeRangeMediaList] = useState([]);
  const [dateRangeMediaList, setDateRangeMediaList] = useState([]);
  const [allMedia, setAllMedia] = useState([]);
  const [activeMedia, setActiveMedia] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [shouldRenderComponent, setShouldRenderComponent] = useState(false);
  const formDataHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((prevVal) => ({ ...prevVal, [name]: value }));
  };
  const handleModeChange = (event, newMode) => {
    if (newMode) {
      setMode(newMode);
    }
  };
  console.log(dateRangeMediaList);
  const submitHandler = () => {
    const mediaList = activeMedia.map((ele) => ele.id);

    const transformTimeMediaList = timeRangeMediaList.map((entry) => ({
      ...entry,
      mediaList: entry.mediaList.map((media) => media.id),
    }));
    debugger;
    const transformDateMediaList = dateRangeMediaList.map((entry) => ({
      ...entry,
      timeRangeMediaList: entry.timeRangeMediaList.map((timeRange) => ({
        ...timeRange,
        mediaList: timeRange.mediaList.map((media) => media.id),
      })),
    }));
    const body = {
      ...formData,
      mediaList,
      timeRangeMediaList: transformTimeMediaList,
      dateRangeMediaList: transformDateMediaList,
    };

    if (isEdit) {
      invokeApi(
        HTTP_METHODS.PUT,
        `${HOSTNAME}${REST_URLS.PLAYLIST}/${editObj?.id}`,
        body
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Media Updated Successfully", { autoClose: 2000 });
            setIsAddPlaylist(false);
            setEditObj({});
            getPlaylist({ limit: 10, page: 1, sortBy: "-createdAt" });
          }
        })
        .catch((err) => console.error(err));
    } else {
      invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${REST_URLS.PLAYLIST}`, body)
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Playlist Created Successfully", { autoClose: 2000 });
            setIsAddPlaylist(false);
            getPlaylist({ limit: 10, page: 1, sortBy: "-createdAt" });
          }
        })
        .catch((err) => console.error(err));
    }
  };
  const getMedia = (params) => {
    plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.MEDIA}`, null, params)
      .then((response) => {
        if (response) {
          const activeMediaIds = activeMedia.map((media) => media.id);
          const newMediaItems = response.results.filter(
            (media) => !activeMediaIds.includes(media.id)
          );
          setAllMedia(newMediaItems);
        } else {
          console.error("Fetching Media failed");
        }
      })
      .catch((err) => {
        console.error("Fetching Media failed");
      });
  };

  const onDragEnd = (result) => {
    setRefresh(Math.random());
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let add;
    let allMediaList = allMedia;
    let activeMediaList = activeMedia;
    // Source Logic
    if (source.droppableId === "ALL_MEDIA") {
      add = allMediaList[source.index];
      allMediaList.splice(source.index, 1);
    } else {
      add = activeMediaList[source.index];
      activeMediaList.splice(source.index, 1);
    }

    // Destination Logic
    if (destination.droppableId === "ALL_MEDIA") {
      allMediaList.splice(destination.index, 0, add);
    } else {
      activeMediaList.splice(destination.index, 0, add);
    }

    setActiveMedia(activeMediaList);
    setAllMedia(allMediaList);
  };
  useEffect(() => {
    if (editObj?.id) {
      setIsEdit(true);
      setFormData({
        name: editObj?.name,
        description: editObj?.description,
      });
      setActiveMedia(editObj?.mediaList);
      setTimeRangeMediaList(editObj?.timeRangeMediaList || []);
      setDateRangeMediaList(editObj?.dateRangeMediaList || []);
    }
  }, []);

  useEffect(() => {
    getMedia({ page: 1, limit: 50, sortBy: "-createdAt" });
  }, []);
  useEffect(() => {
    if (isEdit && allMedia?.length > 0) {
      const filteredMedia = allMedia.filter(
        (ele) => !editObj.mediaList?.some((media) => media.id === ele.id)
      );
      // Update state only if it's different from the current state
      if (JSON.stringify(filteredMedia) !== JSON.stringify(allMedia)) {
        setAllMedia(filteredMedia);
      }
      setShouldRenderComponent(true);
    }
  }, [editObj, allMedia]);

  return (
    <div className="addPlaylistCon ">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          mr: 5,
        }}
      >
        <Box
          sx={{ display: "flex", mt: 4, alignItems: "center" }}
          className="page-body"
        >
          <ArrowBackIcon
            sx={{ mr: 1, cursor: "pointer" }}
            onClick={() => {
              setIsAddPlaylist(false);
              setEditObj({});
            }}
          />
          <p className="section-name">
            {" "}
            {isEdit ? "Edit Playlist" : "Add Playlist"}
          </p>
        </Box>

        <Button
          color="success"
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={submitHandler}
          disabled={
            !formData.name || !formData.description || activeMedia.length === 0
          }
        >
          Save
        </Button>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {" "}
        <ToggleButtonGroup
          value={mode}
          exclusive
          onChange={handleModeChange}
          aria-label="Playlist Mode"
          color="info"
        >
          <ToggleButton value="default">Default</ToggleButton>
          <ToggleButton value="timewise">Timewise</ToggleButton>
          <ToggleButton value="datewise">Datewise</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {mode === "default" && (
        <div className="page-body">
          <Grid container spacing={3}>
            <Grid item md={7}>
              <div className="form-con">
                <p className="form-header">Playlist Basic Info</p>
                <Box sx={{ display: "flex", gridGap: "20px" }}>
                  <TextField
                    label="Name"
                    size="small"
                    fullWidth
                    name="name"
                    onChange={formDataHandler}
                    required
                    value={formData.name || ""}
                  />
                  <TextField
                    label="Description"
                    size="small"
                    fullWidth
                    name="description"
                    sx={{ mr: 2 }}
                    onChange={formDataHandler}
                    required
                    value={formData.description || ""}
                  />
                </Box>
              </div>
              <div className="form-con">
                <DragDropContext onDragEnd={onDragEnd}>
                  <MediaSelector
                    allMedia={allMedia}
                    activeMedia={activeMedia}
                    getMedia={getMedia}
                  />
                </DragDropContext>
              </div>
            </Grid>
            <Grid item md={5}>
              <div className="form-con">
                <p className="form-header">Playlist Preview</p>
                {activeMedia.length > 0 ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <SlidePreview mediaList={activeMedia} />
                  </Box>
                ) : (
                  <div className="television-screen">
                    <div className="previewCon">
                      <p>Preview</p>
                    </div>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      )}
      {mode === "timewise" && (
        <TimewisePlaylist
          timeRangeMediaList={timeRangeMediaList}
          setTimeRangeMediaList={setTimeRangeMediaList}
        />
      )}
      {mode === "datewise" && (
        <DatewisePlaylist
          dateRangeMediaList={dateRangeMediaList}
          setDateRangeMediaList={setDateRangeMediaList}
        />
      )}
    </div>
  );
};

export default AddPlaylist;
