export const Theme = {
  typography: {
    fontFamily: ["Lato-Regular"],
  },
  shape: {
    borderRadius: 8,
  },
  palette: {
    primary: {
      main: "#001A53",
    },
    secondary: {
      main: "#CDDBF8",
    },
    success: {
      main: "#34C759",
      contrastText: "#fff",
    },
  },
};
