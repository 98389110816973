import React, { useEffect, useState } from "react";
import { usePromiseTracker } from "react-promise-tracker";
import "./index.scss";
import { LinearProgress } from "@mui/material";

const Loader = () => {
  const { promiseInProgress } = usePromiseTracker();
  const [isVisible, setIsVisible] = useState(promiseInProgress);

  useEffect(() => {
    if (!promiseInProgress) {
      // Add a delay before fully hiding the loader to allow the transition
      const timeout = setTimeout(() => setIsVisible(false), 200);
      return () => clearTimeout(timeout);
    } else {
      setIsVisible(true);
    }
  }, [promiseInProgress]);

  return (
    isVisible && (
      <div
        className={`loading-container ${!promiseInProgress ? "hidden" : ""}`}
      >
        <div className="loading-progress">
          <span className="loader"></span>
        </div>
      </div>
    )
  );
};

export default Loader;
