import React, { useState, useEffect } from "react";
import "./index.scss";
import MuiTable from "../../core/mui-table";
import { getHeaderConfig } from "./config";
import {
  Autocomplete,
  Box,
  Button,  
  Grid,
  TextField,
  Typography
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddStoreGroup from "./addStoreGroup";
import CustomModal from "../../core/modal";
import SearchIcon from "@mui/icons-material/Search";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";
import StoresList from "./stores-list";

const StoreGroup = () => {
  const [tableData, setTableData] = useState({});
  const [editObj, setEditObj] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt"
  });
  const [isDelete, setIsDelete] = useState({
    isOpen: false,
    data: {}
  });
  const [storeList, setStoreList] = useState([]);
  const [allStoreGroupList, setAllStoreGroupList] = useState([]);
  const [searchStoreGroup, setSearchStoreGroup] = useState("");
  const [searchStore, setSearchStore] = useState("");
  const [isAddStoreGroup, setIsAddStoreGroup] = useState(false);
  const [addStore, setAddStore] = useState({
    isOpen: false,
    storeData: {},
    groupData: {}
  });

  const loadStoresAndStoreGroups = () => {
    getStores({
      page: 1,
      limit: 1000,
      sortBy: "-createdAt"
    });
    getStoreGroups(
      {
        page: 1,
        limit: 1000,
        sortBy: "-createdAt"
      },
      true
    );
  };

  const clickHandler = (data, type) => {
    switch (type) {
      case "edit":
        setIsAddStoreGroup(true);
        setEditObj(data);
        break;
      case "delete":
        setIsDelete({
          isOpen: true,
          data
        });
        break;
      case "addStore":
        setAddStore((prevVal) => ({
          ...prevVal,
          isOpen: true,
          groupData: data
        }));
        break;
      default:
        break;
    }
  };
  const storeEdit = (data) => {
    setAddStore({
      isOpen: true,
      storeData: data,
      groupData: data?.storeGroupId
    });
  };
  const deleteHandler = () => {
    invokeApi(
      HTTP_METHODS.DELETE,
      `${HOSTNAME}${REST_URLS.STORE_GROUP}/${isDelete.data.id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
          setIsDelete({
            isOpen: false,
            data: {}
          });
        } else {
          toast.success("Store Group Deleted Successfully", {
            autoClose: 2000
          });
          setIsDelete({
            isOpen: false,
            data: {}
          });
          getStoreGroups(filters);
        }
      })
      .catch((err) => console.error(err));
  };
  const getStores = (filter) => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.STORE}`, null, filter)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setStoreList(res.results);
        }
      })
      .catch((err) => console.error(err));
  };

  const getStoreGroups = (filter, allData = false) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.STORE_GROUP}`,
      null,
      filter
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          if (allData) {
            setAllStoreGroupList(res.results);
            setTableData({
              ...res,
              results: res.results.splice(0, 10)
            });
          } else {
            setTableData(res);
          }
        }
      })
      .catch((err) => console.error(err));
  };

  const searchHandler = () => {
    if (searchStore?.storeGroupId?.id) {
      getStoreGroups({
        ...filters,
        page: 1,
        _id: searchStore?.storeGroupId?.id
      });
    } else if (searchStoreGroup || searchStore) {
      getStoreGroups({ ...filters, page: 1, name: searchStoreGroup });
    } else {
      getStoreGroups({ ...filters, page: 1 });
    }
  };

  const formDataHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAddStore((prevVal) => ({
      ...prevVal,
      storeData: { ...addStore.storeData, [name]: value }
    }));
  };

  const storeSubmitHandler = () => {
    if (!addStore?.storeData?.name || !addStore?.storeData?.storeCode) {
      toast.error("Store name and code is required", { autoClose: 2000 });
      return;
    }

    const payload = { storeGroupId: addStore?.groupData?.id };
    delete addStore?.storeData?.storeGroupId;
    delete addStore?.storeData?.createdBy;
    delete addStore?.storeData?.accountId;

    for (let i in addStore?.storeData) {
      if (addStore?.storeData[i]) {
        payload[i] = addStore?.storeData[i];
      }
    }
    if (addStore?.storeData?.id) {
      delete payload?.id;
      invokeApi(
        HTTP_METHODS.PUT,
        `${HOSTNAME}${REST_URLS.STORE}/${addStore?.storeData?.id}`,
        payload
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Store Updated Successfully", { autoClose: 2000 });
            setAddStore({ isOpen: false, groupData: {}, storeData: {} });
            loadStoresAndStoreGroups();
          }
        })
        .catch((err) => console.error(err));
    } else {
      invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${REST_URLS.STORE}`, payload)
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Store created successfully", { autoClose: 2000 });
            setAddStore({ isOpen: false, groupData: {}, storeData: {} });
            loadStoresAndStoreGroups();
          }
        })
        .catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    loadStoresAndStoreGroups();
  }, []);

  return (
    <>
      <div className='media-con'>
        <h1 className='header'>Store Groups</h1>
        <Grid container md={12} sx={{ mb: 2 }}>
          <Grid item md={8} sx={{ display: "flex", alignItems: "center" }}>
            <Grid item md={3.5}>
              <TextField
                value={searchStoreGroup}
                fullWidth
                onChange={(e) => setSearchStoreGroup(e.target.value)}
                label='Enter Store Group'
                variant='outlined'
                size='small'
              />
            </Grid>
            <Grid item md={3.5} sx={{ ml: 2 }}>
              <Autocomplete
                options={storeList}
                getOptionLabel={(option) => option.name}
                onChange={(e, val) => {
                  if (val) {
                    setSearchStore(val);
                  } else {
                    setSearchStore("");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Select Store'
                    variant='outlined'
                    size='small'
                  />
                )}
              />
            </Grid>
            <Grid item md={3.5}>
              <Button
                variant='outlined'
                color='success'
                startIcon={<SearchIcon />}
                onClick={searchHandler}
                sx={{ ml: 3 }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            md={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center"
            }}
          >
            <Button
              variant='outlined'
              size='small'
              startIcon={<AddOutlinedIcon />}
              onClick={() => setIsAddStoreGroup(true)}
            >
              Add Store Group
            </Button>
          </Grid>
        </Grid>

        <MuiTable
          columnsList={getHeaderConfig()}
          dataList={tableData?.results}
          filters={filters}
          onClick={clickHandler}
          pageCount={tableData?.totalPages}
          onChange={(page) => {
            setFilters({
              ...filters,
              page
            });
            getStoreGroups({ ...filters, page: page });
          }}
          renderCollapseContent={(row) => {
            const stores = storeList?.filter(
              (store) =>
                store?.storeGroupId?.id === row?.id &&
                (!searchStore || store?.id === searchStore?.id)
            );

            return <StoresList data={stores} onClick={storeEdit} />;
          }}
        />
      </div>

      {isAddStoreGroup && (
        <CustomModal
          onClose={() => {
            setIsAddStoreGroup(false);
            setEditObj({});
          }}
          title=' Store Group'
          classesToPreventClosing={["MuiAutocomplete-option", "Mui-focused"]}
        >
          <AddStoreGroup
            setIsAddStoreGroup={setIsAddStoreGroup}
            getStoreGroups={getStoreGroups}
            editObj={editObj}
            setEditObj={setEditObj}
            loadStoresAndStoreGroups={loadStoresAndStoreGroups}
          />
        </CustomModal>
      )}
      {isDelete?.isOpen && (
        <CustomModal
          title='Delete Group Store'
          onClose={() => {
            setIsDelete({
              isOpen: false,
              data: {}
            });
          }}
        >
          <h1>Are You Sure?</h1>
          <Button variant='contained' color='error' onClick={deleteHandler}>
            Delete
          </Button>
        </CustomModal>
      )}
      {addStore?.isOpen && (
        <CustomModal
          onClose={() => {
            setAddStore({
              isOpen: false,
              storeData: {},
              groupData: {}
            });
          }}
          title={addStore?.storeData?.id ? "Edit Store" : "Add Store"}
          classesToPreventClosing={["MuiAutocomplete-option", "Mui-focused"]}
        >
          <Typography sx={{ mb: 2 }}>
            Store Group: {addStore?.groupData?.name}
          </Typography>
          <Box className='add-store-modal'>
            <TextField
              label='Name'
              size='small'
              fullWidth
              name='name'
              required
              value={addStore?.storeData?.name}
              onChange={formDataHandler}
            />
            <TextField
              label='Store Code'
              size='small'
              fullWidth
              required
              name='storeCode'
              value={addStore?.storeData?.storeCode}
              onChange={formDataHandler}
            />
            <TextField
              label='Description'
              size='small'
              fullWidth
              name='description'
              value={addStore?.storeData?.description}
              onChange={formDataHandler}
            />
            <TextField
              label='City'
              size='small'
              fullWidth
              name='city'
              value={addStore?.storeData?.city}
              onChange={formDataHandler}
            />
            <TextField
              label='Store Type'
              size='small'
              fullWidth
              name='storeType'
              value={addStore?.storeData?.storeType}
              onChange={formDataHandler}
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{
                native: true
              }}
            >
              <option value=''>Select Store Type</option>
              <option value='Airport'>Airport</option>
              <option value='Food Court'>Food Court</option>
              <option value='Highway'>Highway</option>
              <option value='High Street'>High Street</option>
              <option value='Hospital'>Hospital</option>
              <option value='Institute'>Institute</option>
              <option value='Mall'>Mall</option>
              <option value='Tech Park'>Tech Park</option>
              <option value='Metro Station'>Metro Station</option>
            </TextField>

            <TextField
              label='Area'
              size='small'
              fullWidth
              name='area'
              value={addStore?.storeData?.area}
              onChange={formDataHandler}
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{
                native: true
              }}
            >
              <option value=''>Select Area</option>
              <option value='North'>North</option>
              <option value='South'>South</option>
              <option value='East'>East</option>
              <option value='West'>West</option>
              <option value='Central'>Central</option>
            </TextField>

            {addStore?.storeData?.id && (
              <Autocomplete
                options={allStoreGroupList}
                getOptionLabel={(option) => option.name}
                value={addStore.groupData}
                onChange={(event, newValue) => {
                  setAddStore((prevVal) => ({
                    ...prevVal,
                    groupData: newValue
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Select Store Group'
                    variant='outlined'
                    size='small'
                  />
                )}
              />
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            <Button
              variant='outlined'
              onClick={() => {
                setAddStore({
                  isOpen: false,
                  storeData: {},
                  groupData: {}
                });
              }}
              color='error'
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              onClick={storeSubmitHandler}
              color='success'
            >
              Save
            </Button>
          </Box>
        </CustomModal>
      )}
    </>
  );
};

export default StoreGroup;
