/** @format */

import React, { useState } from "react";
import { toast } from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PublishIcon from "@mui/icons-material/Publish";
import "./index.scss";
import { IconButton } from "@mui/material";
import { invokeUploadFile } from "../../utils/http-service";
import { HOSTNAME } from "../../utils/endpoints";

const SiFileUpload = ({
  title,
  clsName,
  url,
  accept,
  callBack,
  inputclassName,
  fileFormats,
  setDataFunction,
  showFileName,
  documentName,
}) => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const onFormSubmit = (e) => {
    e.preventDefault(); // Stop form submit
    fileUpload();
  };
  const onChange = (e) => {
    if (fileFormats && !fileFormats.includes(e.target.files[0].type.trim())) {
      toast.error("File provided is not of correct format", {
        autoClose: 2000,
      });
      return;
    }
    setFile(e.target.files[0]);
  };

  const fileUpload = () => {
    const formData = new FormData();
    formData.append("upload", file);
    return invokeUploadFile(`${HOSTNAME}${url}`, formData)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Uploaded Successfully", { autoClose: 1000 });
          setFile(null);
          callBack && callBack(response);
          setUploadStatus("");
        } else {
          toast.error(
            (response.data && response.data.message) || "Upload Failed"
          );
          setUploadStatus("");
        }
      })
      .catch((err) => {
        setUploadStatus("");
        setFile(null);
        toast.error(`Upload Failed ${err?.response?.data?.message}`);
      });
  };

  return (
    <div className="relativePosition">
      <form className={`spidle-upload-file ${clsName}`}>
        {file === null ? (
          <IconButton
            title="Attach file"
            size="small"
            style={{
              background: "#1756f3",
              color: "#fff",
              borderRadius: 4,
              cursor: "pointer",
            }}
          >
            <label
              htmlFor={title ? title : "file-upload"}
              className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary upload-button"
            >
              Upload Media <PublishIcon fontSize="small" />
            </label>
          </IconButton>
        ) : (
          <label
            title={(file && file.name) || ""}
            className="uploaded-file-name"
          >
            {(file && file.name) || ""}
          </label>
        )}
        <input
          id={title ? title : "file-upload"}
          type="file"
          accept={accept}
          className={`${inputclassName ? inputclassName : ""}`}
          onChange={(event) => {
            onChange(event);
            event.target.value = null;
          }}
        />
        {file !== null && (
          <span>
            <IconButton
              color="primary"
              onClick={onFormSubmit}
              title="Upload"
              disabled={uploadStatus}
            >
              <CheckCircleOutlineIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => {
                if (setDataFunction) {
                  setDataFunction(null);
                }
                setFile(null);
              }}
              title="Clear"
              disabled={uploadStatus}
            >
              <HighlightOffIcon />
            </IconButton>
          </span>
        )}
      </form>
    </div>
  );
};

export default SiFileUpload;
