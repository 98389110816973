import React, { useState, useEffect } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DragDropContext } from "react-beautiful-dnd";
import MediaSelector from "./mediaCard";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import CustomButtonComponent from "../../core/custom-button";
import dayjs from "dayjs";
import SlidePreview from "../../core/slide-preview";
import { toast } from "react-toastify";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import moment from "moment";
const DatewisePlaylist = ({ dateRangeMediaList, setDateRangeMediaList }) => {
  const [allMedia, setAllMedia] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [activeMedia, setActiveMedia] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const getMedia = (params) => {
    plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.MEDIA}`, null, params)
      .then((response) => {
        if (response) {
          const activeMediaIds = activeMedia.map((media) => media.id);
          const newMediaItems = response.results.filter(
            (media) => !activeMediaIds.includes(media.id)
          );
          setAllMedia(newMediaItems);
        } else {
          console.error("Fetching Media failed");
        }
      })
      .catch((err) => {
        console.error("Fetching Media failed");
      });
  };
  const onDragEnd = (result) => {
    setRefresh(Math.random());

    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let add;
    let allMediaList = allMedia;
    let activeMediaList = activeMedia;
    // Source Logic
    if (source.droppableId === "ALL_MEDIA") {
      add = allMediaList[source.index];
      allMediaList.splice(source.index, 1);
    } else {
      add = activeMediaList[source.index];
      activeMediaList.splice(source.index, 1);
    }

    // Destination Logic
    if (destination.droppableId === "ALL_MEDIA") {
      allMediaList.splice(destination.index, 0, add);
    } else {
      activeMediaList.splice(destination.index, 0, add);
    }

    setActiveMedia(activeMediaList);
    setAllMedia(allMediaList);
  };
  const formatTime = (value) => dayjs(value).format("HH:mm");
  const clearHandler = () => {
    setActiveMedia([]);
    setStartTime(null);
    setEndTime(null);
    setStartDate("");
    setEndDate("");
    getMedia({ page: 1, limit: 50, sortBy: "-createdAt" });
  };
  const submitHandler = () => {
    if (!startDate || !endTime) {
      toast.error("Start Date End Date is required", { autoClose: 2000 });
      return;
    }
    if (!startTime || !endTime) {
      toast.error("Start and End Time is required", { autoClose: 2000 });
      return;
    }
    if (activeMedia.length < 1) {
      toast.error("Atleast one media is required for creating Playlist", {
        autoClose: 2000,
      });
      return;
    }
    const newEntry = {
      startDate,
      endDate,
      timeRangeMediaList: [
        ...(dateRangeMediaList.find(
          (entry) => entry.startDate === startDate && entry.endDate === endDate
        )?.timeRangeMediaList || []),
        {
          startTime,
          endTime,
          mediaList: [...activeMedia],
        },
      ],
    };
    setDateRangeMediaList((prevList) => {
      const otherEntries = prevList.filter(
        (entry) => entry.startDate !== startDate || entry.endDate !== endDate
      );
      return [...otherEntries, newEntry];
    });

    clearHandler();
  };

  useEffect(() => {
    getMedia({ page: 1, limit: 50, sortBy: "-createdAt" });
  }, [activeMedia]);
  return (
    <div className="page-body">
      <Grid container spacing={3}>
        <Grid item md={7}>
          <div className="form-con">
            <p className="form-header">Timewise Playlist Form</p>

            <Grid container sx={{ mt: 3 }}>
              <Grid item md={3}>
                <TextField
                  label="Start Date"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  label="End Date"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item md={2.8}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Start Time"
                    value={startTime ? dayjs(startTime, "HH:mm") : null}
                    onChange={(newValue) => {
                      const formattedTime = formatTime(newValue);
                      setStartTime(formattedTime);
                    }}
                    views={["hours", "minutes"]}
                    format="HH:mm"
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={0.3}></Grid>
              <Grid item md={2.6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="End Time"
                    value={endTime ? dayjs(endTime, "HH:mm") : null}
                    onChange={(newValue) => {
                      const formattedTime = formatTime(newValue);
                      setEndTime(formattedTime);
                    }}
                    views={["hours", "minutes"]}
                    format="HH:mm"
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <CustomButtonComponent
                variant="contained"
                color="success"
                sx={{ mr: 3 }}
                onClick={submitHandler}
              >
                Add
              </CustomButtonComponent>
            </Box>
          </div>
          <div className="form-con">
            <DragDropContext onDragEnd={onDragEnd}>
              <MediaSelector
                allMedia={allMedia}
                activeMedia={activeMedia}
                getMedia={getMedia}
              />
            </DragDropContext>
          </div>
        </Grid>
        <Grid item md={5}>
          <div className="form-con">
            <p className="form-header">Playlist Preview</p>
            <div>
              {activeMedia.length > 0 ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <SlidePreview mediaList={activeMedia} />
                </Box>
              ) : (
                <div className="television-screen">
                  <div className="previewCon">
                    <p>Preview</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          {dateRangeMediaList.length > 0 && (
            <div className="form-con">
              {dateRangeMediaList?.map((date) => {
                return (
                  <div>
                    <Typography sx={{ mt: 1, mb: 1 }}>
                      From {moment(date?.startDate).format("DD-MM-YYYY")} to{" "}
                      {moment(date?.endDate).format("DD-MM-YYYY")}
                    </Typography>
                    <div className="time-range-con">
                      {date?.timeRangeMediaList?.map((time, index) => {
                        return (
                          <div
                            className={`chip-com-con purple-bg`}
                            style={{
                              width: "fit-content",
                              marginRight: "10px ",
                            }}
                            onClick={() => {
                              setActiveMedia(time.mediaList);
                            }}
                          >
                            <p>
                              {time.startTime} - {time.endTime}
                            </p>
                            <DeleteTwoToneIcon
                              onClick={() => {
                                setDateRangeMediaList((prevList) => {
                                  const updatedList = prevList
                                    .map((entry) => {
                                      if (
                                        entry.startDate === date.startDate &&
                                        entry.endDate === date.endDate
                                      ) {
                                        const updatedTimeRangeMediaList =
                                          entry.timeRangeMediaList.filter(
                                            (_, i) => i !== index
                                          );
                                        return updatedTimeRangeMediaList.length >
                                          0
                                          ? {
                                              ...entry,
                                              timeRangeMediaList:
                                                updatedTimeRangeMediaList,
                                            }
                                          : null; // Mark empty date entries as null
                                      }
                                      return entry;
                                    })
                                    .filter((entry) => entry !== null); // Remove null entries
                                  return updatedList;
                                });
                              }}
                              fontSize="small"
                              color="error"
                              sx={{ ml: 1, cursor: "pointer" }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default DatewisePlaylist;
