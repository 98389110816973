import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
export const getHeaderConfig = () => {
  return [
    {
      label: "Name",
      id: "name",
    },
    {
      label: "Description",
      id: "description",
    },
    {
      label: "Actions",
      id: "action",
      render: (data, onClick) => {
        return (
          <div>
            <EditIcon
              color="info"
              onClick={() => onClick(data, "edit")}
              sx={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];
};
export const getTagFormConfig = () => {
  const list = [
    {
      name: "name",
      type: "text",
      label: "Name",
      placeholder: "Name",
      required: true,
      fieldGridSize: 12,
    },

    {
      name: "description",
      type: "textarea",
      label: "Description",
      placeholder: "Description",
      required: true,
      fieldGridSize: 12,
    },
  ];

  return list;
};
