import React, { useState, useEffect } from "react";
import "./index.scss";
import MuiTable from "../../core/mui-table";
import { getHeaderConfig } from "./config";
import { Box, Button, Grid, Autocomplete, TextField } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SearchIcon from "@mui/icons-material/Search";
import CustomModal from "../../core/modal";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";
import { getTagFormConfig } from "./config";
import FormGenerator from "../../core/form-generator";
import CustomButtonComponent from "../../core/custom-button";
import { handleFormValidation } from "../../utils";

const Tags = () => {
  const [tableData, setTableData] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
    paginate: true,
  });
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [editObj, setEditObj] = useState({});
  const [isAddTag, setIsAddTag] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [searchTag, setSearchTag] = useState("");
  const clickHandler = (data, type) => {
    switch (type) {
      case "edit":
        setIsAddTag(true);
        setEditObj(data);
        setFormData((prevVal) => ({
          ...prevVal,
          name: data.name,
          description: data.description,
        }));
        break;

      default:
        break;
    }
  };
  const getTags = (filter, isFullList = false) => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.TAG}`, null, filter)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          if (isFullList) {
            const result = res?.map((ele) => ({
              label: ele.name,
              value: ele.name,
            }));
            setTagList(result);
          } else {
            setTableData(res);
          }
        }
      })
      .catch((err) => console.error(err));
  };

  const submitHandler = () => {
    const isValid = handleFormValidation(
      getTagFormConfig(),
      setErrors,
      formData
    );
    if (isValid) {
      const payload = { ...formData };

      if (editObj?.id) {
        invokeApi(
          HTTP_METHODS.PUT,
          `${HOSTNAME}${REST_URLS.TAG}/${editObj?.id}`,
          payload
        )
          .then((res) => {
            if (res.message) {
              toast.error(res.message, { autoClose: 2000 });
            } else {
              toast.success("Tag Updated Successfully", { autoClose: 2000 });
              setEditObj({});
              setIsAddTag(false);
              getTags(filters);
              setFormData({});
            }
          })
          .catch((err) => console.error(err));
      } else {
        invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${REST_URLS.TAG}`, payload)
          .then((res) => {
            if (res.message) {
              toast.error(res.message, { autoClose: 2000 });
            } else {
              toast.success("Tag Created Successfully", { autoClose: 2000 });
              setIsAddTag(false);
              getTags(filters);
              setFormData({});
            }
          })
          .catch((err) => console.error(err));
      }
    }
  };
  const searchHandler = () => {
    if (searchTag) {
      getTags({ ...filters, page: 1, name: searchTag });
    } else {
      getTags({ ...filters, page: 1 });
    }
  };
  useEffect(() => {
    getTags(filters);
  }, []);
  useEffect(() => {
    getTags({ ...filters, paginate: false }, true);
  }, []);
  return (
    <>
      <div className="playlist-con">
        <h1 className="header">Tags</h1>
        <Grid container md={12} sx={{ mb: 2 }}>
          <Grid item md={8} sx={{ display: "flex", alignItems: "center" }}>
            <Grid item md={3.5}>
              <Autocomplete
                options={tagList}
                renderInput={(params) => (
                  <TextField {...params} label="Search Tag" size="small" />
                )}
                onChange={(e, val) => {
                  if (val?.value) {
                    setSearchTag(val.value);
                  } else {
                    setSearchTag("");
                  }
                }}
              />
            </Grid>
            <Grid item md={3.5}>
              <Button
                variant="outlined"
                color="success"
                startIcon={<SearchIcon />}
                onClick={searchHandler}
                sx={{ ml: 3 }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            md={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              size="small"
              startIcon={<AddOutlinedIcon />}
              onClick={() => setIsAddTag(true)}
            >
              Add Tag
            </Button>
          </Grid>
        </Grid>

        <MuiTable
          columnsList={getHeaderConfig()}
          dataList={tableData?.results || []}
          filters={filters}
          onClick={clickHandler}
          pageCount={tableData?.totalPages}
          onChange={(page) => {
            setFilters({
              ...filters,
              page,
            });
            getTags({ ...filters, page });
          }}
        />
      </div>

      {isAddTag && (
        <CustomModal
          onClose={() => {
            setIsAddTag(false);
            setEditObj({});
            setFormData({});
          }}
          title={editObj?.id ? "Edit Tag" : "Add Tag"}
        >
          <FormGenerator
            config={getTagFormConfig()}
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            setErrors={setErrors}
            fieldGridSize={12}
          />
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <CustomButtonComponent
              variant="contained"
              onClick={submitHandler}
              color="success"
            >
              Submit
            </CustomButtonComponent>
          </Box>
        </CustomModal>
      )}
    </>
  );
};

export default Tags;
