import React from "react";
import { Stack, Avatar } from "@mui/material";
import AndroidIcon from "@mui/icons-material/Android";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import TvTwoToneIcon from "@mui/icons-material/TvTwoTone";
import TIZEN_ICON from "../../resources/images/tizen.png";
const ScreenIcons = ({ androidCount, tizenCount }) => {
  const totalScreens = androidCount + tizenCount;
  const maxIcons = Math.min(totalScreens, 6); // Show max 6 icons

  // Create an array with 'A' for Android and 'T' for Tizen
  let screenArray = [
    ...Array(androidCount).fill("A"),
    ...Array(tizenCount).fill("T"),
  ];

  // Shuffle the array randomly
  screenArray = screenArray.sort(() => Math.random() - 0.5).slice(0, maxIcons);

  return (
    <Stack direction="row" spacing={-0.9} alignItems="center">
      {screenArray.map((type, index) => (
        <Avatar
          key={index}
          sx={{
            width: 26,
            height: 26,
            backgroundColor: type === "A" ? "#4CAF50" : "#1565C0",
            border: "3px solid white",
          }}
        >
          {type === "A" ? (
            <AndroidIcon fontSize="small" sx={{ color: "white" }} />
          ) : (
            <img src={TIZEN_ICON} alt="tizen" width="100%" />
          )}
        </Avatar>
      ))}
    </Stack>
  );
};

export default ScreenIcons;
