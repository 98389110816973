import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, Autocomplete, Box } from "@mui/material";
import "./index.scss";

import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";

const AddStoreGroup = ({
  setIsAddStoreGroup,
  getStoreGroups,
  editObj,
  setEditObj,
  loadStoresAndStoreGroups,
}) => {
  const [specificationList, setSpecificationList] = useState([]);
  const [formData, setFormData] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const formDataHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((prevVal) => ({ ...prevVal, [name]: value }));
  };
  const getStoreGroupList = () => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.STORE_GROUP}`)
      .then((res) => {
        if (!res.message) {
          // setStoreGroups(res.results);
        }
      })
      .catch((err) => console.error(err));
  };
  const getSpecificationList = (value) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.SEARCH_SPECIFICATIONS}/${value}`
      )
        .then((res) => {
          const result = res.map((ele) => ({
            label: ele.name,
            value: ele.id,
          }));
          setSpecificationList(result);
        })
        .catch((err) => console.error(err));
    }, 100);
  };
  const submitHandler = () => {
    if (!formData?.name || !formData.groupCode) {
      toast.error("Group Name and Code is Required", { autoClose: 2000 });
      return;
    }
    const payload = {};
    for (let i in formData) {
      if (formData[i]) {
        payload[i] = formData[i];
      }
    }
    if (isEdit) {
      invokeApi(
        HTTP_METHODS.PUT,
        `${HOSTNAME}${REST_URLS.STORE_GROUP}/${editObj?.id}`,
        payload
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Store Group Updated Successfully", {
              autoClose: 2000,
            });
            setIsAddStoreGroup(false);
            setEditObj({});
            setIsEdit(false);
            loadStoresAndStoreGroups();
          }
        })
        .catch((err) => console.error(err));
    } else {
      invokeApi(
        HTTP_METHODS.POST,
        `${HOSTNAME}${REST_URLS.STORE_GROUP}`,
        payload
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Store Group created successfully", {
              autoClose: 2000,
            });
            setIsAddStoreGroup(false);
            loadStoresAndStoreGroups();
          }
        })
        .catch((err) => console.error(err));
    }
  };
  const onInputChange = (event, value) => {
    if (value?.trim()) {
      getSpecificationList(value);
    } else {
      setFormData((prevVal) => ({ ...prevVal, specificationId: "" }));
    }
  };
  useEffect(() => {
    if (editObj?.id) {
      setIsEdit(true);
      setFormData({
        name: editObj?.name,
        description: editObj?.description,
        groupCode: editObj?.groupCode,
        specificationId: editObj?.specificationId?.id,
      });
    }
  }, []);
  useEffect(() => {
    getStoreGroupList();
    getSpecificationList();
  }, []);
  return (
    <div>
      <div className="add-store-modal">
        <TextField
          label="Name"
          size="small"
          fullWidth
          name="name"
          required
          value={formData?.name}
          onChange={formDataHandler}
        />
        <TextField
          label="Group Code"
          size="small"
          fullWidth
          name="groupCode"
          required
          value={formData?.groupCode}
          onChange={formDataHandler}
        />
        <TextField
          label="Description"
          size="small"
          fullWidth
          name="description"
          value={formData?.description}
          onChange={formDataHandler}
        />

        <Autocomplete
          options={specificationList}
          renderInput={(params) => (
            <TextField {...params} label="Select Specification" size="small" />
          )}
          onInputChange={onInputChange}
          defaultValue={editObj?.specificationId?.name || ""}
          onChange={(e, val) => {
            if (val?.value) {
              setFormData((prevVal) => ({
                ...prevVal,
                specificationId: val.value,
              }));
            } else {
              setFormData((prevVal) => ({ ...prevVal, specificationId: "" }));
            }
          }}
        />
      </div>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <Button
          variant="outlined"
          onClick={() => {
            setIsAddStoreGroup(false);
          }}
          color="error"
          sx={{ mr: 2 }}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={submitHandler} color="success">
          Save
        </Button>
      </Box>
    </div>
  );
};

export default AddStoreGroup;
