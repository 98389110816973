import React, { useState } from "react";
import { FormControl, FormHelperText, Grid } from "@mui/material";
import SiTextField from "../si-textfield";
import SiDropdown from "../si-dropdown";
import SiAutocomplete from "../si-autocomplete";
import SiDatePicker from "../si-datepicker";
import SiDateTimePicker from "../si-timePicker";

const FormGenerator = ({
  config,
  formData,
  setFormData,
  setErrors,
  errors,
  fieldGridSize = 4,
  spacing = 4,
}) => {
  const [imagePreview, setImagePreview] = useState({});
  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => {
      if (prevErrors[name]) {
        const { [name]: _, ...rest } = prevErrors;
        return rest;
      }
      return prevErrors;
    });
  };

  const handleImageChange = (name, file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setImagePreview((prev) => ({
        ...prev,
        [name]: reader.result,
      }));
    };

    if (file) {
      reader.readAsDataURL(file);
    }
    handleChange(name, file);
  };

  const renderField = (field) => {
    switch (field.type) {
      case "text":
      case "email":
      case "number":
      case "password":
      case "date":
        return (
          <SiTextField
            error={!!errors[field.name]}
            name={field.name}
            placeholder={field.placeholder}
            type={field.type}
            onChange={(e) => handleChange(field.name, e.target.value)}
            value={formData[field.name] || ""}
            disabled={field.disabled ? true : false}
            title={
              field.disabled && formData[field.name] && formData[field.name]
            }
          />
        );

      case "select":
        return (
          <SiDropdown
            error={!!errors[field.name]}
            options={field.options}
            value={formData[field.name] || ""}
            onChange={(e) => handleChange(field.name, e.target.value)}
            placeholder={field.placeholder || field.label}
            disabled={field.disabled}
          />
        );

      case "autocomplete":
        return (
          <SiAutocomplete
            error={!!errors[field.name]}
            options={field.options}
            value={formData[field.name] || ""}
            onChange={(value) => handleChange(field.name, value)}
            placeholder={field.placeholder || field.label}
            disabled={field.disabled}
            multiple={field.multiple}
          />
        );
      case "datePicker":
        return (
          <SiDatePicker
            error={!!errors[field.name]}
            name={field.name}
            placeholder={field.placeholder}
            // onChange={(value) => handleChange(field.name, value?.toDate())}
            onChange={(value) => handleChange(field.name, value.toDate())}
            value={formData[field.name] || ""}
            minDate={field.minDate}
            disabled={field.disabled}
          />
        );
      case "textarea":
        return (
          <SiTextField
            id={field.name}
            value={formData[field.name] || ""}
            placeholder={field.placeholder}
            multiline
            rows={4}
            variant="outlined"
            onChange={(e) => handleChange(field.name, e.target.value)}
            error={!!errors[field.name]}
          />
        );
      case "dateTimePicker":
        return (
          <SiDateTimePicker
            id={field.name}
            value={formData[field.name] || ""}
            placeholder={field.placeholder}
            multiline
            rows={4}
            variant="outlined"
            onChange={(e) => {
              handleChange(field.name, e);
            }}
            error={!!errors[field.name]}
          />
        );
      case "image":
        return (
          <div>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleImageChange(field.name, e.target.files[0])}
              disabled={field.disabled}
            />
            {imagePreview[field.name] && (
              <img
                src={imagePreview[field.name]}
                alt=""
                style={{
                  maxWidth: "100%",
                  maxHeight: "200px",
                  marginTop: "10px",
                }}
              />
            )}
            <FormHelperText>{errors[field.name]}</FormHelperText>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Grid container sx={{ display: "flex", alignItems: "flex-end" }}>
        {config.map((field, idx) => {
          return (
            <Grid
              item
              md={field.fieldGridSize || fieldGridSize}
              xs={12}
              sx={{ pr: { md: 3, xs: 0, sm: 0 } }}
            >
              <FormControl
                key={idx}
                fullWidth
                variant="outlined"
                margin="dense"
                error={!!errors[field.name]}
                size="small"
              >
                <p style={{ marginTop: "0px" }}>
                  {field.label}
                  {field.required && " *"}
                </p>

                {renderField(field)}
                <FormHelperText>{errors[field.name]}</FormHelperText>
              </FormControl>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default FormGenerator;
