import { Grid, TextField } from "@mui/material";
import React from "react";
import { getSpecFormConfig } from "../config";
import FormGenerator from "../../../core/form-generator";

const Details = ({ setFormData, formData, errors, setErrors }) => {
  const formDataHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((prevVal) => ({ ...prevVal, [name]: value }));
  };
  return (
    <div>
      <div className="form-con" style={{ width: "70%", marginTop: "3rem" }}>
        <Grid
          container
          md={12}
          sx={{ display: "flex", justifyContent: "center", mt: 2 }}
        >
          <Grid item md={11}>
            <FormGenerator
              config={getSpecFormConfig()}
              formData={formData}
              setFormData={setFormData}
              errors={errors}
              setErrors={setErrors}
              fieldGridSize={6}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Details;
